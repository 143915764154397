import React, {useEffect, useState} from 'react';
import Cookies from 'js-cookie'

import Introduction, {Ending, QuestText, QuestImage, QuestPositionBlur, QuestPositionImage, QuestPositionScale, QuestMapCollect, QuestAudio, QuestOptions, QuestSearchImage, QuestMagnifier, QuestDnD} from './QuestFunctions';

import MapMenu from './MapMenu';
import './QuestMapCharacter.css';
import ottowin from '../images/gamescreen/ottowin.png'

import Navbar from './Navbar';
import Tutorial from './Tutorial';
import QuestHeader from './QuestHeader';


const initstats={time: 0, pausecount: 0, solved: 0,skips: 0, hints: 0, fails:0, points: 0}

const QuestMapFrame = ({position, contentarray}) => {
	const nquests=contentarray.quests.length

	const cookienames={
		queststate: contentarray.intro.id+"queststate",
		started: contentarray.intro.id+"started",
		stats: contentarray.intro.id+"stats",
	}

	const [success, setSuccess]=useState(false)
	const [points, setPoints]=useState(0)
	const [stats, setStats]=useState(()=>{
		  let initState=Cookies.get(cookienames.stats)
		  initState=(initState)?JSON.parse(initState):initstats
		  console.log(initState);
		  return (initState)
	  })
	const [starttime, setStarttime]=useState(0)

	const[queststate, setQuestState]=useState(()=>{
		const initState=parseInt(Cookies.get(cookienames.queststate))
		return (initState)||0})

	const[modalcontent, setModalContent]=useState(0)
 	const[modal, setModal]=useState(false)
	const[started, setStarted]=useState(()=>{
		const initState=Cookies.get(cookienames.started)
		return (initState==='true')})


const activateNext=((how, points)=>{
	let finalpoints=stats.points
	if(how==='solved'){
		setSuccess(true)
		let currentpoints=points*(Math.pow(0.9,stats.hints))*(Math.pow(0.8,stats.fails))
		finalpoints+=currentpoints
		setPoints(currentpoints)
		
		
		setTimeout(()=>{
			setSuccess(false)
		},3000)
	}
	setStats({...stats, fails: 0, hints: 0, points: finalpoints})
	console.log(stats);
	
	setQuestState(queststate=>{
		console.log(queststate);
		console.log(nquests);
		return((queststate<nquests)&&queststate+1)})
	
})
const openQuest=((id)=>{
	if(id==='outro'){
		
		setStarted(false)
	}
	setModalContent(id)
	setModal(true)

})

const running=((value)=>{

	if(value==="run"){
/* 		if(queststate===0){
			activateNext()
		} */
		setStarttime(new Date().getTime()-stats.time*1000)
		setStarted(true)
		setModal(false)
		console.log(stats);
	}else if(value==="pause"){
		
		
			let pausecount=stats.pausecount+1
			let dtime=parseInt((new Date().getTime()-starttime)/1000)
			setStats({...stats, time: dtime, pausecount: pausecount})
			setStarted(false)
		
		

	}else if(value==="restart"){
		console.log(cookienames);
		//running(false)
		setQuestState(0)
		setStarted(false)
		setStats(initstats)
		
	}else if(value==="stop"){
		let pausecount=stats.pausecount+1
			let dtime=parseInt((new Date().getTime()-starttime)/1000)
			setStats({...stats, time: dtime, pausecount: pausecount})
			setStarted(false)
		
	}
	
})

useEffect(()=>{
	//!modal&&setTimeout(function() { window.scrollTo(1, '-1px'); }, 100);
	!modal&&window.scrollTo({top: -500, left: 0, behavior: 'smooth'})

},[modal])

useEffect(()=>{
	if(started){
		if(starttime===0){
			setStarttime(new Date().getTime()-stats.time*1000)
		}else{
			let dtime=parseInt((new Date().getTime()-starttime)/1000)
			setStats({...stats, time: dtime})
		}
		
	}
	Cookies.set(cookienames.queststate, queststate, { expires: 30 })
	Cookies.set(cookienames.started, started, { expires: 30 })
},[modal, modalcontent, started, queststate])

useEffect(()=>{
	Cookies.set(cookienames.stats, JSON.stringify(stats), { expires: 30 })
},[stats])


    return (
		<>
		<Navbar running={running} started={started} pausecount={stats.pausecount} gamenav/>
		<div>
			
			{(modal/* ||!started */)&&<div className="modal" onClick={()=>setModal(false)} >
				
					<div className="modal-content" onClick={(e)=>{e.stopPropagation()}}>
						{<button className="close" onClick={()=>{
							setModal(false)
						}}>&times;</button>}
						
							

{/* 								if(modalcontent===index){
									let nquests=contentarray.quests.length-2
									if(content.type==='intro'){
										return(<Introduction key={"quest"+index} running={running} stats={stats} started={started} setSolved={activateNext} setModal={setModal} content={content} length={nquests}/>)
									}
									else if(content.type==='outro'){
										return(<Ending key={"quest"+index} done={(queststate>index)} running={running} stats={stats} setSolved={activateNext} setModal={setModal} content={content} />)
									}
									else if(!started){
										return(<><h4>Pause</h4><Tutorial/></>)
									}
									else{ */}
						{(started)?<>
							<QuestHeader content={contentarray.quests[modalcontent]} index={modalcontent+1} length={nquests}/>
						{												
							contentarray.quests.map((content, index)=>{	
								if(modalcontent===index){
										switch(content.type){
									case 'text':
										return(<><QuestText key={"quest"+index} done={(queststate>index)} stats={stats} setStats={setStats} setSolved={activateNext} content={content} />{(queststate>index)&&<button type='button' className='solved-button' onClick={()=>{setModal(false)}}>{'>>'}</button>}</> )
									case 'options':
										return(<><QuestOptions key={"quest"+index} done={(queststate>index)} stats={stats} setStats={setStats} setSolved={activateNext} content={content} />{(queststate>index)&&<button type='button' className='solved-button' onClick={()=>{setModal(false)}}>{'>>'}</button>}</> )
									case 'image':
										return(<> <QuestImage key={"quest"+index} done={(queststate>index)} stats={stats} setStats={setStats} setSolved={activateNext} content={content} /> </>)
									case 'audio':
										return(<><QuestAudio key={"quest"+index} done={(queststate>index)} stats={stats} setStats={setStats} setSolved={activateNext} content={content} /></>)
									case 'mapcollect':
										return(<><QuestMapCollect key={"quest"+index} done={(queststate>index)} stats={stats} setStats={setStats} setSolved={activateNext} content={content} position={position}/></>)
									case 'positionblur':
										return(<><QuestPositionBlur key={"quest"+index} done={(queststate>index)} stats={stats} setStats={setStats} setSolved={activateNext} content={content} position={position}/></>)
									case 'positionscale':
										return(<><QuestPositionScale key={"quest"+index} done={(queststate>index)} stats={stats} setStats={setStats} setSolved={activateNext} content={content} position={position}/></>)
									case 'positionimage':
										return(<><QuestPositionImage key={"quest"+index} done={(queststate>index)} stats={stats} setStats={setStats} setSolved={activateNext} content={content} position={position}/></>)
									case 'dnd':
										return(<><QuestDnD key={"quest"+index} done={(queststate>index)} stats={stats} setStats={setStats} setSolved={activateNext} content={content}/></>)
									case 'magnifier':
										return(<><QuestMagnifier key={"quest"+index} done={(queststate>index)} stats={stats} setStats={setStats} setSolved={activateNext} content={content}/></>)
									case 'searchimage':
										return(<><QuestSearchImage key={"quest"+index} done={(queststate>index)} stats={stats} setStats={setStats} setSolved={activateNext} content={content}/></>)
									}
									}	
							}
							)
						}
						
						</>:<>
						
						{(modalcontent==='outro')?<Ending running={running} stats={stats} content={contentarray.outro} />:
						<Introduction running={running} stats={stats} started={started} setSolved={activateNext} setModal={setModal} content={contentarray.intro} length={nquests}/>
						}
						</>}		
						
						{success&&<div className="win" >
							<p >+{parseInt(points)}</p>
							<img src={ottowin} width="100%" />
							
							</div>}
				</div>
			</div>}
					
			<MapMenu contentarray={contentarray} queststate={queststate} openQuest={openQuest} length={nquests} />
	
					</div>
			</>
    )
}
export default QuestMapFrame;

