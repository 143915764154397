import { useState } from "react"


const CodeWord = () => {
	const[code, setCode]=useState("")
	const solution='ottonen'

    return (
			<div className="codeword">
				{(solution.toUpperCase()===code.toUpperCase())&&<div>Stromelbe</div>}
				<input type="text" className='input-code' placeholder="..." onChange={(e)=>{
					setCode(e.target.value)
				}} />
			</div>	
    )
}
export default CodeWord;
