import React, {useEffect, useState, Component, useContext} from 'react';
import Cookies from 'js-cookie'
import CryptoJS from 'crypto-js';
import LanguageContext from './LanguageContext';

import * as turf from "@turf/turf";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import './QuestMap.css';
import playbutton from '../images/ottoplay.png'
import refreshbutton from '../images/global/refresh.png'

import ShowMap from './ShowMap';
import MyLocation from './MyLocation';
import Tutorial from './Tutorial';
import QuestHeader from './QuestHeader';



//const successcolor='#090'
const failcolor='#900'
const plaincolor='#1b263b'
const darkone= "#0d1b2a";
const darktwo= "#1b263b";
const darkthree= "#415a77";
const darkfour= "#778da9";
const darkfive= "#e0e1dd";

const initpos=
	{"accuracy": 5, 
	"altitude": 0, 
	"altitudeAccuracy": 0.5, 
	"heading": 170, 
	"latitude": 37.4794533, 
	"longitude": -121.9210617, 
	"speed": 10,
	"timestamp": 1625481776989}


/* TODOs:
	Converting numbers to text
	Sorting mixed letters
	Wordsearch puzzle
	Hangman
	crossword/anagram
	Images which first letters create a word (let images appear in different locations in an area)
	 */

	


const Introduction=({running, content, started, length})=>{
	const [teamname, setTeamname]=useState(()=>{
		const initState=Cookies.get('teamname')
		return (initState)||''})
	const {deutsch, setDeutsch}=useContext(LanguageContext);
	

	return(
		<div className='quest-frame'>
			
			<h4>{content.heading}</h4>
			{deutsch?<>
			<p>Zuerst braucht ihr einen Teamnamen:</p>
			<input className='teamname' type='text' value={teamname} onChange={(e)=>{setTeamname(e.target.value)}}/>
			<p>Hier gibt's eine kleine Anleitung zum Interface:</p>
			<Tutorial/>
			<p>Alles verstanden? Dann warten jetzt {length} Rätsel auf euch.</p></>:
			<>
			<p>First you'll need a teamname:</p>
			<input className='teamname' type='text' value={teamname} onChange={(e)=>{setTeamname(e.target.value)}}/>
			<p>How it works:</p>
			<Tutorial/>
			<p>Got it? Good, now there are {length} quests waiting on you.</p></>}
			
			{/* <button type='button' className='delete-button' onClick={()=>{
				running("restart")
						}}>Neustart</button> */}
			{(started)?
				<>
					
						
					<button type='button' className='pause-button' onClick={()=>{
					
					running("pause")
					}}>Pause</button>
					</>:<>
					<button type='button' className='start-button' onClick={()=>{
						running("run")
						Cookies.set('teamname', teamname, { expires: 30 })
						}}>Start</button>
					</>
				}
				
		
	</div>
	)
}

const Ending=({running, stats, content})=>{

	const[cryptoscore, setCryptoscore]=useState("")
	
	const {deutsch, setDeutsch}=useContext(LanguageContext);


	useEffect(()=>{
		const teamname=Cookies.get('teamname')
		const cstring=CryptoJS.AES.encrypt(JSON.stringify(stats)+teamname, content.id).toString()
		//console.log(cstring);
		setCryptoscore(cstring)
		running("stop")
	  	console.log(CryptoJS.AES.decrypt('U2FsdGVkX19Kaa8SVnNPCi+hicfGPJADFaUsxqoqpjLkaC4LZhgSwHdCsh7ZNb/0eX9A/SC3faYApDzCrDUe4BwIXlhGpT4XdZ1EGNZCUuPjEPqOofWnYkXi+eLtycXMauamaUCQ2+fLcA1LMp9vvw==', 'mdoneEN').toString(CryptoJS.enc.Utf8));
		
	},[])

	return(
		<div className='quest-frame'>
			{deutsch?<><div className='quest-content'> 
			
			<h4>Glückwunsch, hier ist vorerst das Ende der Rätseltour.</h4>	
		</div>
		<div className='scrollcontent'>
			<p>Ihr habt {parseInt(stats.time/60)}:{stats.time%60}min gebraucht.</p>
			<p>Falls ihr euren Score genauer ausgewertet bekommen wollt, schickt mir folgenden Code und euren Teamnamen per Instagram oder Email:
				
			</p>
			<p style={{overflowX: "scroll", marginBottom: 0}} >{cryptoscore}
			</p>
			<button className='link-button' onClick={()=>{
				 navigator.clipboard.writeText(cryptoscore)
			}}>➡ Code kopieren</button>
<p>Wenn es euch Spass gemacht hat, probiert gerne auch noch eine andere Tour aus. Dort gibt es auch noch mehr besondere Rätseltypen. Ansonsten könnt ihr die Tour gerne Teilen: <br/> 
			<span className='link-button' onClick={()=>{
							console.log("sharing...");
							console.log(navigator.share);
							if(navigator.share){
								console.log("sharing.2.");
								navigator.share({
									title: 'Teile dein Abenteuer',
									url: 'https://queenofquest.de'
								}).then(() => {
									console.log('Thanks for sharing!');
								})
								.catch(console.error);
								} else {
									console.log("sharing not supported");
							}
							console.log("sharing.3.");
						}}>➡ Teilen</span></p>

			<p>Infos und Updates über neue Touren veröffentliche ich auf Instagram: <br/><a className="link-button" href="https://www.instagram.com/queen_of_quest/">➡ @queen_of_quest</a></p>
			<p>Falls ihr mich für weitere Touren unterstützen wollt, könnt ihr das natürlich auch gerne tun: <br/><a className="link-button" href="https://www.paypal.com/paypalme/jonathanmischke">➡ Paypal</a> </p>
			<p>Über Feedback würde ich mich ansonsten auch interessieren: <br/><a className="link-button" href="mailto:mischkejonathan@gmail.com?subject=QueenofQuest">➡ Mail</a> </p>
			<p><small>{content.cliffhanger}</small> </p>
			</div>
			</>:<>
			<div className='quest-content'> 
			
			<h4>Congrats, you made it to the end of tour.</h4>	
		</div>
		<div className='scrollcontent'>
			<p>It took you {parseInt(stats.time/60)}:{stats.time%60}min.</p>
			<p>If you want to get your score evaluated more, send me the following code and your teamname via Instagram or email:
				
			</p>
			<p style={{overflowX: "scroll", marginBottom: 0}} >{cryptoscore}
			</p>
			<button className='link-button' onClick={()=>{
				 navigator.clipboard.writeText(cryptoscore)
			}}>➡ Copy to Clipboard</button>

			<p>Otherwise you can also share the tour: <br/> 
			<span className='link-button' onClick={()=>{
							console.log("sharing...");
							console.log(navigator.share);
							if(navigator.share){
								console.log("sharing.2.");
								navigator.share({
									title: 'Teile dein Abenteuer',
									url: 'https://queenofquest.de'
								}).then(() => {
									console.log('Thanks for sharing!');
								})
								.catch(console.error);
								} else {
									console.log("sharing not supported");
							}
							console.log("sharing.3.");
						}}>➡ Teilen</span></p>

			<p>I post information and updates about new tours on Instagram: <br/><a className="link-button" href="https://www.instagram.com/queen_of_quest/">➡ @queen_of_quest</a></p>
			<p>If you enjoyed it and would like to support the project for further tours, quests and features with a small tip, you are of course welcome to do so: <br/><a className="link-button" href="https://www.paypal.com/paypalme/jonathanmischke">➡ Paypal</a> </p>
			<p>For further Feedback: <br/><a className="link-button" href="mailto:mischkejonathan@gmail.com?subject=QueenofQuest">➡ Mail</a> </p>
			<p><small>{content.cliffhanger}</small> </p>
			</div>
			</>}
		
	</div>
	)
}


//---------------------------------------------------------------------------------------------------------------------------------------------

const QuestText = ({setSolved, stats,setStats, done, content}) => {

    return (
		<div className='quest-frame'>
			
			<div className='scrollcontent'>
			{done&&<>{content.image&&<img className="info-image" alt="" src={content.image}/>}</>}
			{done&&<div className='solution' >{content.info}</div>}
			{!done&&<CheckSolution content={content} stats={stats} setStats={setStats} setSolved={setSolved} />}
			{!done&&<HandleHints setSolved={setSolved} stats={stats} setStats={setStats} content={content} />}
				</div>
			
		</div>		
    )
}
const QuestOptions = ({setSolved, stats,setStats, done, content}) => {
	const [answer, setAnswer]=useState(new Array(content.options.length).fill(false))

    return (
		<div className='quest-frame'>
			
			<div className='scrollcontent'>
				<div className='selection-list'>
				{content.options.map((option, index)=>{
					return(
						<div key={`optionkey`+index} >
						<input type="checkbox" id={`opt`+index} disabled={done} name={`opt`+index} checked={answer[index]} onChange={(e)=>{
							console.log(answer);
							let oldanswer=[...answer]
							oldanswer[index]=e.target.checked
							setAnswer(oldanswer)
						}}/>
						<label htmlFor={`opt`+index}>{option}</label><br/>
						</div>
					)
				})}
				</div>
			{done&&<>{content.image&&<img className="info-image" alt="" src={content.image}/>}</>}
			{done&&<div className='solution' >{content.info}</div>}
			{!done&&<CheckOptions answer={answer} content={content} stats={stats} setStats={setStats} setSolved={setSolved} />}
			{!done&&<HandleHints setSolved={setSolved} stats={stats} setStats={setStats} content={content} />}
				</div>
			
		</div>		
    )
}


const QuestMapCollect = ({setSolved, stats,setStats, done, content}) => {
	const[position, setPosition]=useState(initpos)
	
	const[refresh, setRefresh]=useState(false)

	const mindistance=10;
	
	useEffect(()=>{
		console.log("done: "+done);
		if(!done){
			let localdistance=1000*turf.distance(turf.point([position.latitude, position.longitude]), turf.point(content.targetposition))

			
			console.log(localdistance);
			if(localdistance<mindistance){
			setSolved("solved", content.points)
			}
		}
	},[position, content])

    return (
		<div className='quest-frame'>
			
			<div className='scrollcontent'>
				<MyLocation refresh={refresh} setPosition={setPosition}/>
				
					<ShowMap position={position} done={done} content={content} />

					
				
				
				
				
				{done?
					<div className='solution' >{content.info}</div>
					:<>
						<button className='solve-button'  onClick={(e)=>{
						setRefresh(!refresh)
						}}>Update</button>	
						 
						 <HandleHints setSolved={setSolved} stats={stats} setStats={setStats} content={content} />
					</>}
				
			</div>
			
		</div>		
    )
}

const QuestAudio = ({setSolved, stats,setStats, done, content}) => {
	const[playing, setPlaying]=useState(false)
	let audio=new Audio(content.audio)

	audio.onended=()=>{
		setPlaying(false)
		}

    return (
		<div className='quest-frame'>
			
			<div className='scrollcontent'>
				<button type='button' className="audio-button" onClick={()=>{
					setPlaying(true)
					audio.play()}}>
					{<img className="audio-image" alt="" src={playing ? content.image : playbutton}/>}
					</button>
			{done&&<div className='solution' >{content.info}</div>}
			{!done&&<CheckSolution  content={content} stats={stats} setStats={setStats} setSolved={setSolved} />}
			{!done&&<HandleHints setSolved={setSolved} stats={stats} setStats={setStats} content={content} />}
				</div>
			</div>
				
    )
}



const QuestPositionBlur = ({setSolved, stats,setStats, done, content}) => {
	const[targetdistance, setTargetdistance]=useState(10)
	const[position, setPosition]=useState(initpos)
	const[refresh, setRefresh]=useState(false)

	const targetradius=10

	useEffect(()=>{
		let currentdistance=(turf.distance(turf.point([position.latitude, position.longitude]), turf.point(content.targetposition)))*1000
		setTargetdistance((currentdistance<targetradius)?0:currentdistance)
	},[position, content.targetposition])

    return (
		<div className='quest-frame'>
			
				
			<div className='scrollcontent'>
				<div className="pixelated-container">
					<button  className="refresh-button" onClick={()=>{setRefresh(!refresh)}}>
						<img src={refreshbutton} alt=""/>
					</button>
					
					<img className="pixelated-image" alt="" style={{transform: `translateY(${50}vw) scale(.5)`}}  src={content.image}/>	
					
				</div>
				
				{done?<div className='solution' >{content.info}</div>:
				<>
					<MyLocation refresh={refresh} setPosition={setPosition}/>
					
					{/* <button style={{backgroundColor: darkfive}} className='refresh-button' onClick={()=>{setRefresh(!refresh)}} >Empfang testen</button> */}
					<CheckSolution  content={content} stats={stats} setStats={setStats} setSolved={setSolved} />
					<HandleHints setSolved={setSolved} stats={stats} setStats={setStats} content={content} />
				</>}
			</div>
			
	</div>
    )
}

const QuestPositionScale = ({setSolved, stats,setStats, done, content}) => {
	const[targetdistance, setTargetdistance]=useState(10)
	const[position, setPosition]=useState(initpos)
	const[refresh, setRefresh]=useState(false)

	const targetradius=10

	useEffect(()=>{
		let currentdistance=(turf.distance(turf.point([position.latitude, position.longitude]), turf.point(content.targetposition)))*1000
		setTargetdistance((currentdistance<targetradius)?0:currentdistance)
	},[position, content.targetposition])

    return (
		<div className='quest-frame'>
			<div className='scrollcontent'>
				<div className="pixelated-container">
					<button  className="refresh-button" onClick={()=>{setRefresh(!refresh)}}>
						<img src={refreshbutton} alt=""/>
					</button>
					
				<img className="pixelated-image" alt="" style={{filter: `blur(${targetdistance/10}px)`, transform: `scale(${(1-Math.min(targetdistance/content.maxdistance,.95))})`}}  src={content.image}/>	
				
				</div>
				
				{done?<div className='solution' >{content.info}</div>:
				<>
					<MyLocation refresh={refresh} setPosition={setPosition}/>
					
					{/* <button style={{backgroundColor: darkfive}} className='refresh-button' onClick={()=>{setRefresh(!refresh)}} >Empfang testen</button> */}
					<CheckSolution  content={content} stats={stats} setStats={setStats} setSolved={setSolved} />
					<HandleHints setSolved={setSolved} stats={stats} setStats={setStats} content={content} />
				</>}
			</div>
			
	</div>
    )
}

const QuestPositionImage = ({setSolved, stats,setStats, done, content}) => {
	const mindistance=10;
	const [currentdistance, setCurrentdistance]=useState(100)
	const[position, setPosition]=useState(initpos)
	const[refresh, setRefresh]=useState(false)

	useEffect(()=>{

		if(!done){
			let localdistance=1000*turf.distance(turf.point([position.latitude, position.longitude]), turf.point(content.targetposition))

			let dis=Math.min(Math.max(((localdistance/content.maxdistance*100)), 0), 100);
			console.log(localdistance);
			console.log(dis);
			setCurrentdistance(dis)
			if(localdistance<mindistance){
			setSolved('solved', content.points)
			}
		}
	},[position, content])



    return (
		<div className='quest-frame'>
			
			<div className='scrollcontent'>
				<div className='pixelated-container'>
				<img className="image-search" alt="" src={content.image}/>
				<button  className="refresh-button" onClick={()=>{setRefresh(!refresh)}}>
						<img src={refreshbutton} alt=""/>
					</button>
				</div>
				<svg viewBox="0 0 100 10" xmlns="http://www.w3.org/2000/svg">
					<defs>
						<mask id="linemask" maskUnits="userSpaceOnUse">
							<line x1="0" y1="5" x2="100" y2="5" stroke="#fff" strokeWidth="7"   />
						</mask>
					</defs>
					<line x1="0" y1="5" x2="100" y2="5" stroke="#eee" strokeWidth="5" />
					<line x1="0" y1="5" x2="100" y2="5" stroke="#e1e" strokeWidth="5" mask="url(#linemask)" style={{transform: `translateX(-`+currentdistance+`px)`}} className="progressbar"/>
					
				</svg>

				{done?
					<div className='solution' >{content.info}</div>
					:<>
						 <MyLocation refresh={refresh} setPosition={setPosition}/>
						 <button style={{backgroundColor: darkone}} className='solve-button' onClick={()=>{setRefresh(!refresh)}} >Aktualisieren</button>
						 <HandleHints setSolved={setSolved} stats={stats} setStats={setStats} content={content} />
					</>}
					
					</div>
			
	</div>
    )
}


const QuestImage = ({setSolved, stats,setStats, done, content}) => {
	


    return (
		<div className='quest-frame'>
		
		<div className='scrollcontent'>
		<img className="image-search" alt="" src={content.image}/>
		{done&&<div className='solution' >{content.info}</div>}
		{!done&&<CheckSolution content={content} stats={stats} setStats={setStats}  setSolved={setSolved} />}
		{!done&&<HandleHints setSolved={setSolved} stats={stats} setStats={setStats} content={content} />}
			</div>
		
	</div>
    )
}
const QuestMagnifier = ({setSolved, stats,setStats, done, content}) => {
	


    return (
		<div className='quest-frame'>
		

		<div className='scrollcontent'>
			{!done&&<div className='mag-area'>
				<div className='mag-scroll'>
					<img className="mag-image" alt="" src={content.image}/>
				</div>
			
				<div className='mag-glass'>
				</div>
			</div>}

		{done&&<div className='solution' >{content.info}</div>}
		{!done&&<CheckSolution content={content} stats={stats} setStats={setStats}  setSolved={setSolved} />}
		{!done&&<HandleHints setSolved={setSolved} stats={stats} setStats={setStats} content={content} />}
		</div>
		
	</div>
    )
}



const QuestSearchImage = ({setSolved, stats,setStats, done, content}) => {
	


    return (
		<div className='quest-frame'>

		<div className='scrollcontent'>
			{!done&&<div className='mag-area'>
				<div className='mag-scroll'>
				<TransformWrapper>
					<TransformComponent>
					<img className="zoom-image" alt="" src={content.image}/>
					</TransformComponent>
				</TransformWrapper>
					
				</div>
			
				{/* <div className='mag-glass'>
				</div> */}
			</div>}

		{done&&<div className='solution' >{content.info}</div>}
		{!done&&<CheckSolution content={content} stats={stats} placeholder={content.placeholder} setStats={setStats}  setSolved={setSolved} />}
		{!done&&<HandleHints setSolved={setSolved} stats={stats} setStats={setStats} content={content} />}
		</div>
		
	</div>
    )
}

const QuestDnD = ({setSolved, stats,setStats, done, content}) => {
	const[playing, setPlaying]=useState(false)



    return (
		<div className='quest-frame'>
			<div>
				<DragDropContext>
					<Droppable droppableId='images' direction='horizontal'>
						{(provided)=>(
							<div {...provided.droppableProps} ref={provided.innerRef} >
								{content.images.map((image, index)=>{
								return(
									<Draggable key={`drag`+index} draggableId={`drag`+index} index={index} >
										{(provided)=>(
											<img className="dnd-image" draggable alt="" src={image}  {...provided.draggableProps} ref={provided.innerRef} {...provided.dragHandleProps}/>
										)}
									
									</Draggable>
								)
								})}
								{provided.placeholder}
							</div>
						)}
						
				</Droppable>
			</DragDropContext>
			</div>
			
			<div>
			{done&&<div className='solution' >{content.info}</div>}
			{!done&&<CheckSolution stats={stats} setStats={setStats}  content={content} setSolved={setSolved} />}
			{!done&&<HandleHints setSolved={setSolved} stats={stats} setStats={setStats}  content={content} />}
				</div>
			
		</div>		
    )
}

//---------------------------------------------------------------------------------------------------------------------------------------------



const HandleHints = ({setSolved, stats,setStats, content}) => {

	const[hint, setHint]=useState(0)
	const {deutsch, setDeutsch}=useContext(LanguageContext);

    return (
		<>
			{(hint<content.tipps.length)&&<button type='button' className='hint-button' onClick={()=>{
				setHint((current)=>{return(current+1)})
				let hintcount=stats.hints+1
				setStats({...stats, hints: hintcount})
				}}>Tipp {hint+1}</button>}
			{(hint===content.tipps.length)&&<button type='button' className='hint-button' style={{backgroundColor: darkthree}} onClick={()=>{
				setSolved("skiped", 0)
				}}>{deutsch?'Überspringen':'Skip'}</button>}
			
			<div>
				{content.tipps.map((tipp, index)=>{
					if(hint>index){
						return (<p key={index}>{tipp}</p>)
					}
				})}
				
			</div>
		</>		
    )
}


const CheckSolution = ({setSolved, stats,setStats, placeholder, content}) => {
	const[buttoncolor, setButtonColor]=useState(darktwo)
	const[answer, setAnswer]=useState("")
	const {deutsch, setDeutsch}=useContext(LanguageContext);

	const showError=(()=>{
		setButtonColor(failcolor)
		let failcount=stats.fails+1
				setStats({...stats, fails: failcount})
		setTimeout(()=>{
			console.log("timer");
			setButtonColor(darktwo)
		},1600)
	})

	const fuzzyCheck=((answer, solutions)=>{
		let normanswer=answer.toUpperCase().replace(/\s+/g, '')
		
		console.log();
		let checked=false;
		solutions.forEach((solution)=>{
			let normsolution=solution.toUpperCase().replace(/\s+/g, '')
			if(normanswer===normsolution){
				checked=true
			}
		})
		console.log(checked);
		return(checked)
	})

    return (
		<>
			<input type="text" className='input-text' placeholder={placeholder} onChange={(e)=>{
				setAnswer(e.target.value)
			}} onKeyDown={(e)=>{

				if(e.key==="Enter"){
					fuzzyCheck(answer, content.solution)? setSolved("solved", content.points):showError()
				}
			}} />
			<button style={{backgroundColor: buttoncolor}} className='solve-button'  onClick={(e)=>{
				fuzzyCheck(answer, content.solution)? setSolved("solved", content.points):showError()
				}}>{deutsch?'Lösen':'Solve'}</button>		
		</>		
    )
}
const CheckOptions = ({answer, setSolved, stats,setStats, content}) => {
	const[buttoncolor, setButtonColor]=useState(darktwo)
	const {deutsch, setDeutsch}=useContext(LanguageContext);

	const showError=(()=>{
		setButtonColor(failcolor)
		let failcount=stats.fails+1
				setStats({...stats, fails: failcount})
		setTimeout(()=>{
			console.log("timer");
			setButtonColor(darktwo)
		},1600)
	})

	const optionCheck=((answer, solution)=>{
		console.log(answer);
		console.log(solution);
		let checked=true;
		solution.forEach((value, index)=>{
			if(value!==answer[index]) checked=false
		})
		console.log(checked);
		return(checked)
	})

    return (
		<>
			<button style={{backgroundColor: buttoncolor}} className='solve-button'  onClick={(e)=>{
				optionCheck(answer, content.solution)? setSolved("solved", content.points):showError()
				}}>{deutsch?'Lösen':'Solve'}</button>		
		</>		
    )
}

export default Introduction;
export {QuestText, QuestPositionBlur, QuestPositionScale, QuestImage, QuestAudio, QuestPositionImage, QuestSearchImage, QuestMapCollect, QuestDnD, QuestOptions, QuestMagnifier, Ending};
