import Navbar from "./Navbar";

const Impressum = () => {


    return (
      <>
		<Navbar/>
      <div className="impressum">
			
			  <h2>Impressum</h2>
          <h3>Angaben gemäß § 5 TMG: </h3>
          <p>Jonathan Mischke <br/>Winckelmannstrasse 13 <br/>39108 Magdeburg<br/>Deutschland</p>
          <h3>Kontakt</h3>
          <p>E-Mail: mischkejonathan@gmail.com</p>
			
		
					</div>
          </>
    )
}
export default Impressum;
