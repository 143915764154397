import React, {useState} from 'react';
import { MapContainer, Marker, TileLayer, useMap,SVGOverlay , ImageOverlay } from 'react-leaflet';
import L ,{LatLngBounds} from 'leaflet';

import ottomarker from '../images/map/ottomarker.png'
import arrow from '../images/map/arrow.png'
import refreshbutton from '../images/global/refresh.png'

const initpos=
	{"accuracy": 5, 
	"altitude": 0, 
	"altitudeAccuracy": 0.5, 
	"heading": 170, 
	"latitude": 37.4794533, 
	"longitude": -121.9210617, 
	"speed": 10,
	"timestamp": 1625481776989}


const ShowMap = ({position, done, content}) => {

	
	/* const[refresh, setRefresh]=useState(false) */

	const ottoMarker = L.icon({     //custom marker icon on the map
		iconUrl: ottomarker,
		iconSize: [80, 80], // size of the icon
		iconAnchor: [40, 40], // point of the icon which will correspond to marker's location
	  })
	  const targetMarker = L.icon({     //custom marker icon on the map
		  iconUrl: content.targetmarker,
		  iconSize: [24, 48], // size of the icon
		  iconAnchor: [12, 24], // point of the icon which will correspond to marker's location
		})

	  
	  const bounds = new LatLngBounds(content.bounds[0], content.bounds[1])

    return (
		<div className="mapformat">
			
			<MapContainer attributionControl={false} center={content.startposition} zoom={18} scrollWheelZoom={false}  dragging={true} touchZoom={false} zoomControl={false}>
			
				{/* <ChangeView center={[position.latitude, position.longitude]} zoom={18} />  */}
				{/* 
				<ImageOverlay   url={klosterbergbg}  bounds={bounds}  opacity={1}  zIndex={10}/> */}
				<Marker icon={ottoMarker} position={[position.latitude, position.longitude]} />
				
				{!done&&<Marker icon={targetMarker} position={content.targetposition}/>}
				
				{/* <ImageOverlay   url={klosterbergbg}  bounds={bounds}  opacity={1}  zIndex={10000}/>
				<ImageOverlay   url={arrow}  bounds={boundsd}  opacity={1}  zIndex={10001}/> */}
				<SVGOverlay attributes={{ stroke: '#e0e1dd', viewBox:"0 0 100 100", fill: 'none' }}  bounds={bounds}>
					<rect x="0" y="0" width="100%" height="100%" fill="#1b263b" />
					<path width="100%" height="100%"
					
					d="m 21.639137,64.44494 c 2.741731,-0.118007 4.320088,-1.632064 6.236606,-2.740327 l 5.552221,-10.560407 c -5.332659,-3.15984 -8.158652,-7.449503 -8.859512,-12.212658 1.915853,-3.287824 2.978628,-10.232827 4.247663,-15.210289 1.269034,-4.977461 2.744329,-7.987381 6.619152,-5.76739 4.018869,-3.862794 12.955204,-2.480289 19.465774,-3.685268 8.484908,0.933589 11.528571,5.082459 16.441965,8.126488 2.464826,14.574488 15.608021,35.327246 15.151471,40.487341 -0.529992,2.365892 -13.067202,10.028509 -15.434954,11.862361 -6.321681,0.498313 -14.231096,-0.704518 -18.331846,2.173364 0.217088,-0.390759 -5.026438,-9.657729 -7.483904,-5.722335 -0.814326,1.304066 -5.229935,4.797415 -5.745263,4.682898 -4.109831,-0.305533 -7.962065,-6.14941 -11.71726,-14.079611"
					id="path848" />
					<path
					d="m 33.427963,51.144206 7.798361,-10.519237 c -1.756833,-5.328078 -4.153905,-10.411359 -0.06682,-17.973877 3.897009,-0.593294 7.831851,-1.64058 11.559408,-0.200452 4.292574,5.462811 10.809194,10.332542 12.695303,16.437077 -10.109344,3.636842 -13.774908,10.162274 -19.042954,15.969353 -2.296114,0.464588 -5.917219,0.99 -7.954041,0.110346 -1.770824,-0.764776 -2.110452,-1.61818 -4.989259,-3.82321"
					id="path850" />
					
					<path
					d="m 54.901041,14.268601 c 0.416536,1.807204 1.047904,3.933486 1.492829,4.974805 4.547004,10.641975 12.26807,17.451443 17.572972,21.181109 4.687239,3.60347 4.844935,7.381153 5.011303,11.158503 -3.056494,5.741817 -7.960238,6.46967 -13.497113,5.479026 C 57.935238,55.110635 49.038644,51.761847 46.371261,54.85707"
					id="path852" />
					<path
					d="m 35.435267,17.953869 -0.293031,-5.60817 27.358262,-1.801407 4.112726,-0.336749 c 1.356614,10.003325 2.367163,19.867948 7.353618,30.216972"
					id="path854" />
					<path
					d="m 54.901041,14.268601 7.372757,-2.04102 0.2267,-1.683289"
					id="path856" />
					<path
					d="m 73.966842,40.424515 -8.552627,-1.536798 0.06682,18.174327 c -0.854822,6.411262 2.921347,11.940431 5.578491,17.682747"
					id="path858" />
					<path
					d="m 78.978145,51.583018 c -4.936899,8.339585 -3.466813,13.504537 -1.5368,18.441598"
					id="path862" />
					<path
					d="m 56.39387,19.243406 c -0.938627,1.563699 -2.047567,2.833221 -3.674959,3.207234"
					id="path864" />
					<path
					d="m 41.159504,22.651092 -5.011301,0.26727 -7.332088,0.802897"
					id="path866" />
					<path
					d="m 35.435267,17.953869 0.712936,4.964493"
					id="path868" />
					<path
					d="m 45.243773,71.195818 -0.689829,-6.042173 c 0.762094,-2.427264 1.4888,-5.082027 1.817317,-10.296575"
					id="path870" />
					<path
					d="M 44.553944,65.153645 C 42.999439,61.680697 41.160911,58.252595 38.417219,54.967414"
					id="path872" />
    			</SVGOverlay>
				

			</MapContainer>
			<img src={arrow} alt="" className="arrow"/>
			{/* <button className="refresh-button"  onClick={()=>{
				setRefresh(!refresh)
				
				}}>
				<img src={refreshbutton} alt="" />
			</button> */}

		</div>
    )
}
export default ShowMap;

function ChangeView({ center, zoom }) {
	
	const map = useMap();

 	const centervall=setInterval(()=>{
		map.setView(center, zoom);
	}, 5000) 
	

	return null;
  }