import { useEffect, useState } from 'react';

import SunCalc from 'suncalc';
import './SVGStyles.css'

import midgroundday from '../images/gamescreen/midgroundday.png'
import midgroundnight from '../images/gamescreen/midgroundnight.png'
import nightforeground from '../images/gamescreen/foregroundnight.png'
import dayforeground from '../images/gamescreen/foregroundday.png'
import backgroundnight from '../images/gamescreen/backgroundnight.png'
import backgroundday from '../images/gamescreen/backgroundday.png'
import ottoidle from '../images/gamescreen/ottoidle.png'
import ottomoving from '../images/gamescreen/ottomoving.png'
import moon from '../images/gamescreen/moon.png'
import sun from '../images/gamescreen/sun.png'

import question from '../images/gamescreen/question.png'

const Character = ({contentarray, queststate, openQuest}) => {

	const[xvalue, setXvalue]=useState(0)
	const[moving, setMoving]=useState(false)
	const[daytime, setDaytime]=useState(true)
	const stepsize=1

	useEffect(()=>{
		setXvalue(queststate)
		const now=new Date()
		const suntimes=(SunCalc.getTimes(/*Date*/ now, 52.126936979287834, 11.624664166300624))
		const time=now.getTime()
		if(time>suntimes.sunrise.getTime()&&time<suntimes.sunset.getTime()){
			setDaytime(true)
		}else{
			setDaytime(false)
		}
	},[])

    return (
		<div className={daytime?'game-frame day':'game-frame night'} onTransitionEnd={()=>setMoving(false)}>
			<div className='game-scroll'>
				{daytime?
				<>
				<img src={sun} alt="skyline" className='skyball' style={{transform: `translateX(-`+xvalue*10+`px)`}}/>
				<img src={backgroundday} alt="skyline" className='background' style={{transform: `translateX(-`+xvalue*100+`px)`}}/>
				<img src={midgroundday}  alt="skyline" className='midground' style={{transform: `translateX(-`+xvalue*200+`px)`}}/>
				</>
					:<>
					<img src={moon} alt="skyline" className='skyball' style={{transform: `translateX(-`+xvalue*10+`px)`}}/>
					<img src={backgroundnight} alt="skyline" className='background' style={{transform: `translateX(-`+xvalue*100+`px)`}}/>
					<img src={midgroundnight}  alt="skyline" className='midground' style={{transform: `translateX(-`+xvalue*200+`px)`}}/>
					</>}
				
				<img src={moving?ottomoving:ottoidle} alt="char" className="character" style={{transform: `translateX(`+xvalue+`px)`}}/>

				{contentarray.length>xvalue&&<button className='right-button no-select'  onClick={()=>{
					setXvalue((prev)=>prev+stepsize)
					setMoving(true)
				}}></button>}
				{(xvalue>0)&&<button className='left-button no-select'  onClick={()=>{
					setXvalue((prev)=>prev-stepsize)
					setMoving(true)
				}}></button>}
			
				
			</div>
			
				<img src={question}  alt="question-button" className='question-button' style={{left: 50+`vw`,transform: `translateX(-`+xvalue*100+`vw)`}} onClick={()=>{openQuest(0)}}/>
			
		{contentarray.map((content, index)=>{
				 if((queststate)===(index)){ 
					 return(
						 <div key={"old"+index}>
						 <img  src={question}  alt="questionbutton" className='question-button ' style={{left: (index-1)*100+50+`vw`,transform: `translateX(-`+xvalue*100+`vw)`, opacity: 0.2}} onClick={()=>{openQuest(index-1)}}/>
							<img src={question}  alt="questionbutton" className='question-button ' style={{left: (index)*100+50+`vw`,transform: `translateX(-`+xvalue*100+`vw)`}} onClick={()=>{openQuest(index)}}/> 
							
						</ div>
				)}
		})}
			{daytime?
					<img src={dayforeground}  alt="skyline" className='foreground' style={{transform: `translateX(-`+xvalue*120+`vw)`}}/>:
					<img src={nightforeground}  alt="skyline" className='foreground' style={{transform: `translateX(-`+xvalue*120+`vw)`}}/>} 
				
				
		</div>
    )
}
export default Character;
