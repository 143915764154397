import dom from '../images/domMDsat.png'
import otto from '../images/otto.gif'
import hyparschale from '../images/mdquests/hyparschale.png'
import domplatz from '../images/mdquests/leben.png'
import halbkugelrechts from '../images/map/halbkugelmarkerrechts.png'


import sueden from '../images/mdquests/sueden.png'
import ottonen from '../images/mdquests/ottonen.jpg'
import bodenmarker from '../images/mdquests/bodenmarker.JPG'
import klostermap from '../images/mdquests/klostermap.jpg'

import testaudio from '../audio/ofshane.mp3'
import gaertner from '../audio/gaertner.mp3'


import ottomarker from '../images/map/ottomarker.png'

/* 
	Grusons Gewächsehäuser
	Anne Frank Mahnmal
	Sternbrücke
	Schiffsmuseum "Württemberg"
	Stadtpark
	Albinmüller-Turm
	Stadthalle
	Pferdetor
	Hyparschale
	Hubbrücke
	Elbbahnhof
	Elbbalkon/Zeitzähler
	Elbtreppen/MDR Funkhaus/Domfelsen
	Schleinufer/Bastion Cleve/Milchkuranstalt
	Wehrturm Hinter der Möllenvogtei
	Remtergang/Dom
	Domplatz
	Hundertwasserhaus
	Kloster
*/

const testRoute={
	intro: {
	id: "mdone",
	icon: ottomarker,
	position: [52.11402417984451, 11.631301887115065],
	bounds: [[52.109087053201506, 11.624900219702923], [52.13629751946341, 11.654885895878001]],
	heading: "Willkommen zu Otto's erster Tour.",
	info: "13"
},
	outro: {
	position: [52.125657, 11.634938],

	cliffhanger: "Eine Sache fehlt eigentlich noch. Wisst ihr was? Vielleicht finden wir es bei der nächsten Tour."

},
	quests:[
	{	type: "intro",
		id: "testing",
		icon: ottomarker,
		position: [52.126198719393656, 11.609908525928976],
		bounds: [[52.128385447931706, 11.606786434874786], [52.124808905411804, 11.61784786441163]],
		heading: "Willkommen zu Otto's erster Tour.",
		info: "13"
	},
	
	{
		type: "searchimage",
		points: 100,
		position: [52.12650722412338, 11.60987362047342],
		quest: "Auf dem Bild haben sich ein paar Fehler eingeschlichen, könnt ihr alle finden?",
		tipps: ["Es sind 5 Stück"],
		placeholder: "z.B. A9, F7",
		image: ottonen,
		solution: ["A0,B4,87"],	/* first row: 0, first column: 0 */
		info: "."
	},
	{
		type: "audio",
		points: 70,
		quest: "Welche Birne begrüßt euch hier?",
		position: [52.11353810002772, 11.631291964344259],
		image: otto,
		audio: gaertner,
		tipps: ["Er schaut recht steinern aus","und besteht aus drei Wörtern"],
		solution: ["Peter Joseph Lenné", "Peter Joseph Lenne"],
		info: "Der harte Kopf gehört zu Peter Joseph Lenné. Er war ein preußischer Gartenkünstler und General-Gartendirektor der königlich-preußischen Gärten."
	},
	{
		type: "mapcollect",
		points: 40,
		position: [52.113525341097834, 11.631782167479072],
		quest: "Ich hab hier im Park was von mir verloren. Kannst du mir helfen, es einzusammeln?",
		tipps: ["Wenn du Otto nicht gesehen hast, funktioniert evtl. deine Standorterkennung nicht richtig. (siehe FAQ)", "Du kannst dich hier auf der Karte auch umsehen."],
		positions: [[52.11394036209371, 11.636669647801677], [52.11602228108212, 11.634910118819517]],
		targetposition:[52.114591, 11.636303],
		startposition: [52.11366195591674, 11.63177334117796],
		bounds: [[52.111528904020915, 11.629245293803788], [52.1171554366302, 11.6383]],
		targetmarker: halbkugelrechts,
		info: "Danke, damit haben wir schonmal eine Halbkugel für mein Experiment."
	},
	{
		type: "text",
		points: 80,
		position: [52.114591, 11.636303],
		quest: "Nun geht es den Weg hinauf zur Überquerung. Oben angekommen stellt sich ein Rätsel. 'Reist man so schnell wie maximal möglich, erreicht man ihn in gut 8 Minuten. Wie oft wurde er hier verbaut?'",
		tipps: ["Was ist die höchste Geschwindigkeit?", "Er wird häufig mit Zacken dargestellt"],
		solution: ["1200", "eintausendzweihundert", "tausendzweihundert"],
		info: "1200 Sterne. Unser nähster Stern ist die Sonne. Das Licht braucht von dort etwa 8:20min für die 150 Millionen km bis zu uns. Zum Mond sind es nur 1,3s. Ganz so weit ist die Tour heute aber nicht."
	},
	{
		type: "positionblur",
		points: 80,
		position: [52.11734207950953, 11.638825244168354],
		quest: "Der Empfang ist schlecht, vielleicht müsst ihr etwas weiter gehen?",
		tipps: ["Mit ihm lassen sich Taschenmonster ganz gut fangen.", "Sie umhüllt Obst und Gemüse."],
		solution: ["Hyparschale", "Hyperschale"],
		targetposition: [52.119505, 11.638615],
		image: hyparschale,
		info: "Du stehst jetzt bald vor der HypArschale aus 1969. Die Stahlbetondecke ist sebsttragend und besteht aus vier hyperbolischen Paraboloiden. Das sind quasi vier 3D Parabeln."
	},
	{
		type: "options",
		points: 100,
		position: [52.12515156221887, 11.637078774573377],
		quest: "Aus welchem Jahr stammt das einzige noch erhaltene Stadttor der Alten Stadt Magdeburg?",
		tipps: ["Ihr findet es in Richtung Dom"],
		options: ["1474", "1501", "1493", "1527"],
		solution: [false, false, true, false],
		info: "Das Tor wurde 1493 erbaut (manche sagen 1495) und wurde unter anderem als Zugang zum erzbischöflichen Elbhafen genutzt."
	},
	{
		type: "magnifier",
		points: 100,
		position: [52.124661947708454, 11.636159164418162],
		quest: "Gehe zum Ort woher dieses Bild stammt. Ihr befindet euch jetzt auf dem Weg der ...?",
		tipps: ["Richtung Süden durch den Remtergang.", "Die Straße ist 'Am Dom'.", "Findet die erste Steintafel."],
		image: ottonen,
		solution: ["Ottonen"],
		info: "Hier liegt der Weg der Ottonen. Die Liudolfinger herrschten im ostfränkisch-deutschen Reich zwischen 919 und 1024. Die drei Kaiser Otto I., II. III. sorgten für die Bezeichung der Ottonen."
	},
	{
		type: "positionimage",
		points: 100,
		position: [52.11604152946992, 11.63246590162408],
		quest: "Also letzte Aufgabe müsst ihr nur noch das Ziel finden.",
		tipps: ["Vielleicht hilft euch Maps"],
		targetposition: [52.114098383369544, 11.633535397693343],
		maxdistance: 300,
		image: klostermap,
		info: "Der Klosterberggarten war der erste Volksgarten Deutschlands und liegt auf dem Grund des ehemaligem Kloster Berge."
	},
	
	{	type: "outro",
		position: [52.125657, 11.634938],
		id: "mdOne",
		cliffhanger: "Eine Sache fehlt eigentlich noch. Wisst ihr was? Vielleicht finden wir es bei der nächsten Tour."
	
	},
	
]
}

const test=[
	
	{
		type: "text",
		quest: "frage",
		tippOne: "Tipp",
		tippTwo: "Tipp",
		solution: ["antwort"],
		info: "Lösungstext"
	},
	{
		type: "dnd",
		points: 100,
		quest: "Welche Reihenfolge stimmt?",
		tipps: [],
		images: [sueden, sueden, sueden, sueden],
		solution: [1,2,3,4],
		info: "Danke, zu meiner Zeit gabs die Grüne Zitadelle noch nicht..."
	},
	{
		type: "Aufgabe",
		quest: "frage'",
		tippOne: "Tipp",
		tippTwo: "Tipp",
		solution: "antwort",
		targetposition: [52.148813, 11.659141],
		image: dom,
		info: "Dies ist der Lösungstext"
	},
	{
		type: "Aufgabe",
		quest: "frage",
		tippOne: "Tipp",
		tippTwo: "Tipp2",
		solution: "antwort",
		image: otto,
		audio: testaudio,
		info: "Dies ist der Lösungstext"
	},
	{
		type: "positionwimmel",
		quest: "Finde Otto und gehe zu ihm",
		tippOne: "Augen auf.",
		tippTwo: "Vielleicht im Nordosten?",
		targetposition: [52.126188, 11.635820],
		image: domplatz,
		info: "Der Domplatz wurde früher auch Neuer Markt genannt."
	},
	{
		type: "positioncollect",
		quest: "Jetzt wird's vielleicht ein bisschen anstrengender...",
		tippOne: "Sammle gelbe Lichter",
		tippTwo: "Meide die roten Bereiche",
		solution: 5,
		image: domplatz,
		info: "Der Domplatz wurde früher auch Neuer Markt genannt."
	},
	{
		type: "positionlab",
		quest: "Finde durch...",
		tippOne: "Augen auf.",
		tippTwo: "Vielleicht im Nordosten?",
		
		targetposition: [52.126188, 11.635820],
		image: domplatz,
		info: "Der Domplatz wurde früher auch Neuer Markt genannt."
	},
	{
		type: "positionlab",
		quest: "Finde durch...",
		tippOne: "Augen auf.",
		tippTwo: "Vielleicht im Nordosten?",
		
		targetposition: [52.126188, 11.635820],
		image: domplatz,
		info: "Der Domplatz wurde früher auch Neuer Markt genannt."
	},
]


export default testRoute;
