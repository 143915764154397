import {Link} from "react-router-dom";

const Footer = () => {


    return (
		<div className="footer">
			
			<Link className="footer-link" to="/datenschutz">Datenschutzerklärung</Link>
			<Link className="footer-link" to="/impressum">Impressum</Link>
			
		</div>
    )
}
export default Footer;
