import React, {useContext} from 'react';

import LanguageContext from './LanguageContext';

import './QuestMap.css';
import explainpoints from '../images/global/explainpoints.png'
import explainposition from '../images/global/explainposition.png'
import explaintipps from '../images/global/explaintipps.png'
import explainsolve from '../images/global/explainlosen.png'
import explainskip from '../images/global/explainskip.png'
import explainpause from '../images/global/explainpause.png'
import explainfullscreen from '../images/global/explainfullscreen.png'
import explainotto from '../images/global/explainotto.png'

import explainsolveEN from '../images/global/explainlosenEN.png'
import explainskipEN from '../images/global/explainskipEN.png'

const Tutorial=()=>{

	const {deutsch, setDeutsch}=useContext(LanguageContext);

	return(

			<div className='holster'>
			{deutsch?<div className='explainslider'>
				<div className='explainslide'>
					<img src={explainotto} alt="Die Aufgaben werden euch angezeigt, wenn ihr auf Otto klickt" />
					<p>Die <strong>Aufgaben</strong> werden euch angezeigt, wenn ihr auf Otto oder das rote X tippt. Die weißen Marker zeigen euch eure gelösten Aufgaben.</p>
				</div>
				<div className='explainslide'>
					<img src={explainpoints} alt="oben werden die maximalen Punkte angezeigt (100)" />
					<p style={{top: '37%'}} >Die <strong>Punkte</strong> oben geben den <strong>Schwierigkeitsgrad</strong> der Aufgabe an. Je schwieriger, desto mehr Punkte sind möglich.</p>
				</div>
				<div className='explainslide'>
					<img src={explaintipps} alt="Nutzen von Tipps gibt Punktabzug" />
					<p style={{top: '40%'}} ><strong>Tipps</strong> können euch helfen die Lösung zu finde, kosten euch allerdings ein paar der erreichbaren Punkte.</p>
				</div>
				<div className='explainslide'>
					<img src={explainsolve} alt="Falsche Anworten geben Punktabzug" />
					<p style={{top: '40%'}} >Ihr könnt so oft versuchen zu <strong>Lösen</strong>, wie ihr wollt. Liegt ihr daneben, wird der Knopf rot und ihr verliert ein paar Punkte.</p>
				</div>
				<div className='explainslide'>
					<img src={explainskip} alt="Überspringen gibt keine Punkte" />
					<p style={{top: '45%'}} ><strong>Überspringen</strong> könnt ihr, wenn alle Tipps aufgebraucht sind. Ihr bekommt für diese Aufgabe keine Punkte.</p>
				</div>
				<div className='explainslide'>
					<img src={explainskip} alt="Positionsdaten sind für manche Aufgaben notwendig" />
					<p style={{top: '20%'}} >Euer <strong>Standort</strong> wird zum Lösen einiger Aufgaben genutzt. Wenn es auf eurem Smartphone nicht funktioniert, müsst ihr die Aufgabe evtl. überspringen. Im FAQ gibt es dazu mehr Infos.</p>
				</div>
				<div className='explainslide'>
					<img src={explainfullscreen} alt="Oben auf dem Logo könnt ich Vollbildschirm aktivieren" />
					<p style={{top: '25%'}} >Der <strong>Vollbildmodus</strong> wird <small>(auf Android)</small> gestartet, wenn ihr auf das Logo tippt. Alternativ könnt ihr die App zum Homebildschirm hinzufügen. Dann verschwindet die Adressleiste dauerhaft.</p>
				</div>
				<div className='explainslide'>
					<img src={explainpause} alt="Ihr könnt das Spiel oben links dreimal pausieren." />
					<p style={{top: '30%'}} >Ihr könnt die Tour dreimal <strong>Pausieren</strong>. Danach läuft die Zeit weiter.</p>
				</div>
				

			</div>:
			<div className='explainslider'>
				<div className='explainslide'>
					<img src={explainotto} alt="Die Aufgaben werden euch angezeigt, wenn ihr auf Otto klickt" />
					<p>The <strong>tasks</strong> are displayed when you tap on Otto or the red X. The white markers show your solved tasks.</p>
				</div>
				<div className='explainslide'>
					<img src={explainpoints} alt="oben werden die maximalen Punkte angezeigt (100)" />
					<p style={{top: '37%'}}>The <strong>points</strong> above indicate the <strong>difficulty</strong> of the task. The more difficult, the more points are possible. </p>
				</div>
				<div className='explainslide'>
					<img src={explaintipps} alt="Nutzen von Tipps gibt Punktabzug" />
					<p style={{top: '40%'}}><strong>Hints</strong> can help you find the solution, but will cost you a few of the achievable points.</p>
				</div>
				<div className='explainslide'>
					<img src={explainsolveEN} alt="Falsche Anworten geben Punktabzug" />
					<p style={{top: '35%'}}>You can try to <strong>solve</strong> as many times as you want. If you miss, the button turns red and you lose a few points.<br/><small>(You can use ü=ue, ö=oe, ä=ae)</small></p>
				</div>
				<div className='explainslide'>
					<img src={explainskipEN} alt="Überspringen gibt keine Punkte" />
					<p style={{top: '40%'}}>You can <strong>skip</strong> when all the tips are used up. You will not get any points for this task.</p>
				</div>
				<div className='explainslide'>
					<img src={explainskipEN} alt="Positionsdaten sind für manche Aufgaben notwendig" />
					<p style={{top: '35%'}}>Your <strong>location</strong> will be used to solve some tasks. If it does not work on your smartphone, you may have to skip the task. See the FAQ for more information.</p>
				</div>
				<div className='explainslide'>
					<img src={explainfullscreen} alt="Oben auf dem Logo könnt ich Vollbildschirm aktivieren" />
					<p style={{top: '25%'}}>The <strong>full screen</strong> mode is started <small>(on Android)</small> when you tap on the logo. Alternatively, you can add the app to the home screen. Then the address bar disappears permanently.</p>
				</div>
				<div className='explainslide'>
					<img src={explainpause} alt="Ihr könnt das Spiel oben links dreimal pausieren." />
					<p style={{top: '30%'}} >You can pause the tour three times. After that, the time continues to run.</p>
				</div>
			

		</div>}
			</div>
	
	)
}


export default Tutorial;