import React, {useState, useMemo} from 'react';
import { MapContainer, Marker, TileLayer, useMap,SVGOverlay , ImageOverlay } from 'react-leaflet';
import L ,{LatLngBounds} from 'leaflet';
import MyLocation from './MyLocation';
import './MapMenu.css'

import mapimage from '../images/mdquests/tourone.jpeg'

//import ottomarker from '../images/map/ottomarker.png'
import arrow from '../images/map/arrow.png'
import crossmarker from '../images/map/crossmarker.png'
import donecrossmarker from '../images/map/donecrossmarker.png'
import refreshbutton from '../images/global/refresh.png'

const initpos=
	{"accuracy": 5, 
	"altitude": 0, 
	"altitudeAccuracy": 0.5, 
	"heading": 170, 
	"latitude": 37.4794533, 
	"longitude": -121.9210617, 
	"speed": 10,
	"timestamp": 1625481776989}


const MapMenu = ({contentarray, queststate, openQuest,length}) => {



	  	const ottoMarker = L.icon({     //custom marker icon on the map
		iconUrl: contentarray.intro.icon,
		iconSize: [64, 64], // size of the icon
		iconAnchor: [32, 32], // point of the icon which will correspond to marker's location
		className: 'movingmarker'
	  })
	  const donetargetMarker = L.icon({     //custom marker icon on the map
		  iconUrl: donecrossmarker,
		  iconSize: [62, 62], // size of the icon
		  iconAnchor: [32, 32], // point of the icon which will correspond to marker's location
		})
		const targetMarker = L.icon({     //custom marker icon on the map
			iconUrl: crossmarker,
			iconSize: [62, 62], // size of the icon
			iconAnchor: [32, 32], // point of the icon which will correspond to marker's location
		  })

	  
	  //const bounds = new LatLngBounds(content.bounds[0], content.bounds[1])
	  const maxbounds = new LatLngBounds(contentarray.intro.bounds)

    return (
		<>
		<div className="mapmenu">
			
			<MapContainer attributionControl={false}   center={contentarray.intro.position} maxBounds={maxbounds} zoomSnap={0.2} zoom={17} minZoom={16} maxZoom={18} scrollWheelZoom={true}  dragging={true} touchZoom={true} zoomControl={false}>
			<TileLayer className='tilefilter'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" opacity={1}
			/>

			{/* <TileLayer url="https://stamen-tiles.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg"/> */}
				
				
				<ImageOverlay className='map-overlay'  url={mapimage}  bounds={maxbounds}  opacity={0}  zIndex={10}/>

				{(queststate===length)?<>
					<Marker icon={targetMarker} eventHandlers={{click: ()=>{openQuest('outro')}}} position={contentarray.outro.position} />
					<ChangeView center={contentarray.outro.position} zoom={18} /> 
				</>:<>
				
				{contentarray.quests.map((content, index)=>{
					

					if(queststate===index){ 
						return(
							
							<Marker icon={targetMarker} eventHandlers={{click: ()=>{openQuest(index)}}} position={content.position} key={"old"+index} />
							
					)}else if(queststate>index&&index>0){
						return(
						<Marker icon={donetargetMarker} eventHandlers={{click: ()=>{openQuest(index)}}} position={content.position} key={"old"+index} />
						)}
			})}
				<ChangeView center={contentarray.quests[queststate].position} zoom={18} /> 
				<Marker zIndexOffset={1} icon={ottoMarker} autoPan={true} position={contentarray.quests[queststate].position} eventHandlers={{click: ()=>{openQuest(queststate)}}} />
				
				</>}

			</MapContainer>
			<img src={arrow} alt="" className="arrow"/>
			
			<div  className='vignetteoverlay'>

			</div>

		</div>
		</>
    )
}
export default MapMenu;

function ChangeView({ center, zoom }) {
	
	const map = useMap();
	/* map.setView(center, zoom); */
	useMemo(()=>{
		map.panTo(center,{duration: 4, animate: true});
	},[center])
	
	
/*  	const centervall=setInterval(()=>{
		map.setView(center, zoom);
	}, 5000)  */
	

	return null;
  }