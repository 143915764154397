import React, {useState, useContext } from 'react';
import {
	Link
  } from "react-router-dom";

import LanguageContext from './LanguageContext';
import mdSkyline from '../images/md_skylineround_plain.png'
import mdoneimage from '../images/mdquests/grusonstart.png'
import mdlndwimage from '../images/mdquests/lndwstart.png'
import mdtwoimage from '../images/mdquests/domstart.png'
import groupicon from '../images/global/groupicon.png'
import brainicon from '../images/global/brainicon.png'
import ideaicon from '../images/global/ideaicon.png'
import phoneicon from '../images/global/phoneicon.png'
import ticketicon from '../images/global/ticketicon.png'
import moneyicon from '../images/global/moneyicon.png'

import Navbar from './Navbar';

import './MainMenu.css';

const testext='lbladf'

const MainMenu = ({highscores}) => {
	const[questnumber, setQuestNumber]=useState(false)
	const[locationid, setLocationID]=useState("md")

	console.log(highscores);
	
	const {deutsch, setDeutsch}=useContext(LanguageContext);

	/* <p>Wenn ihr Fans von Escape Rooms, Exit Games und Rätseln seid und nach einem Outdoor-Adventure sucht, seid ihr hier richtig.  Die Touren sind sowohl für Touristen, als auch für Alteingesessene geeignet. </p>
	<img className="explainimage" src={groupicon} alt="" />
	<p>Am meisten Spass macht diese Tour in einer kleinen Gruppe. Ansonsten braucht ihr nur ein <strong>Smartphone</strong>, ein bisschen  <strong>Grips</strong> und etwas Kreativität.					
	 Ihr könnt die Touren jederzeit kostenlos spielen und müsst nichts buchen oder installieren. Wenn es euch Spass gemacht hat, könnt ihr am Ende ein Trinkgeld da lassen.</p>
	
	{showmore&&<div>
		<p>Die Rätsel sind teilweise recht schwierig zu lösen und fordern manchmal ein bisschen spezielleres Allgemeinwissen. </p>
		<p>
		Deshalb gibt es meistens ein paar Tipps und online recherchieren ist auch erlaubt.</p>
		<p>
		<p> Die meisten Rätsel lassen sich durch die genauere Betrachtung der Umgebung lösen.</p>
		Teilweise hilft es, sich aufzuteilen, falls ihr eine Gruppe seid.</p>
		<p>Je nachdem, wie schwierig die Aufgabe ist, gibt es Punkte zu gewinnen. Der Schwierigkeitsgrad wird oben angezeigt. Für Tipps und bei falsche Antworten müsst ihr von den zu erreichenden Punkten ein paar abgeben.</p>
		<p>Ansonsten kann man einzelne Rätsel auch überspringen, wenn man gar nicht weiterkommt, bekommt dafür allerdings auch keine Punkte.</p>
		<p>Für die beste Appansicht könnt ihr sie auch im Browsermenu installieren, bzw. zum Homescreen hinzufügen.</p>
		<p>Alternativ könnt ihr oben in die Mitte auf das Icon drücken, um in den Vollbildmodus zu kommen.</p>
		<p>Ansonsten gibt es auch noch ein paar mehr Infos im  */

    return (
		<>
		
		<div className="main-menu">
			<Navbar/>
			{deutsch?<div className="menu-text" >
					<h1>Rätseltour in Magdeburg</h1>

					<p>Wenn ihr Fans von Escape Rooms, Exit Games und Rätseln seid und nach einem Outdoor-Adventure sucht, seid ihr hier richtig.</p>
					Was ihr braucht: 
					<div className='listitem'><img className="explainimage" src={phoneicon} alt="smartphoneicon" />
								ein Smartphone</div>
								<div className='listitem'><img className="explainimage" src={brainicon} alt="gehirnicon" />
								ein bisschen Grips</div>
								<div className='listitem'><img className="explainimage" src={ideaicon} alt="ideaicon" />
								etwas Kreativität</div>
								<small>und/oder</small>
								<div className='listitem'><img className="explainimage" src={groupicon} alt="gruppenicon" />
								schlaue Mitspieler</div>
					<p>Ihr könnt die Touren hier direkt kostenlos spielen und müsst nichts buchen oder installieren. </p>
					<p>	Wenn es euch Spass gemacht hat, könnt ihr am Ende ein Trinkgeld da lassen. </p>
					<p>	Es gibt keinen begrenzten Zeitraum wann ihr anfangen müsst, allerdings werden einige Aufgaben im Dunkeln schwieriger. </p>
					<p>	Ansonsten gibt es hier weitere Infos:</p>
					<Link style={{color: "white"}} to="/FAQ">▶ FAQ ◀</Link>
				
				</div>:
				<div className="menu-text" >
				<h1>Scavengerhunt in Magdeburg</h1>
					<p>If you are fans of escape rooms, exit games or riddles in general and are looking for an outdoor-adventure to explore the city in a different way, you are in the right place. </p>
					What you need: 
					<div className='listitem'><img className="explainimage" src={phoneicon} alt="smartphoneicon" />
								a Smartphone</div>
								<div className='listitem'><img className="explainimage" src={brainicon} alt="gehirnicon" />
								a little bit of brains</div>
								<div className='listitem'><img className="explainimage" src={ideaicon} alt="ideaicon" />
								some creativity</div>
								<small>and/or</small>
								<div className='listitem'><img className="explainimage" src={groupicon} alt="gruppenicon" />
								smart teammates</div>
					
					<p>You can play the tours here directly for free and don't need to book or install anything. </p>
					<p>If you enjoyed it, you can leave a tip at the end.</p>
					<p>There is no limit when you have to start, but some tasks become more difficult in the dark. </p>
					<p>Otherwise here you can find more info:</p>
					<Link style={{color: "white"}} to="/FAQ">▶ FAQ ◀</Link>
				</div>}
				
			<div>
				<div className="menu-entry" >
					<button  className={(locationid==="md")?"menu-button menu-open":"menu-button"} onClick={e=>{
						console.log(questnumber);
						(locationid==="md")? setLocationID(""): setLocationID("md")}
						}>

							
						<div className="crop" >
								<img className="skyline-logo" src={mdSkyline} alt="" />
						</div>
						<div className='cityname' >
							Magdeburg
						</div>
						
					</button>
					
				<div className='menu-locationmenu'>
					{(deutsch)?<>
					{/* <div className='menu-locationentry'>
						<p>Anlässlich der <strong>Lange Nacht der Wissenschaft</strong> gibt es eine kleine Rästeltour im Wissenschaftshafen. Die Tour beginnt auf dem Platz am südlichen Ende des Hafenbeckens.</p>
						<a href="https://goo.gl/maps/RGS2kpB4SyhSB5X96"><img className="locationimage" src={mdlndwimage} alt="" /></a>
						<Link className='start-link' to="/wdnldm" ><p>LNDW-Tour</p></Link>
					</div> */}
					
					<div className='menu-locationentry'>
						<p>Tour A beginnt vor dem Eingang der Gruson-Gewächshäusern.</p>
						<a href="https://goo.gl/maps/UYimBmCVp7rJikjdA"><img className="locationimage" src={mdoneimage} alt="" /></a>
						<p>Aktuelle schnellste Zeit: {highscores.deA.time} von Team {highscores.deA.name.slice(0,1)}***{highscores.deA.name.slice(-1)}</p>
						<Link className='start-link' to="/enodm" ><p>Tour A</p></Link>
					</div>

					<div className='menu-locationentry'>
						<p>Tour B beginnt an der Nordseite des Domplatzes.<br/>
							<small>(Beta)</small>
						</p>
					<a href="https://goo.gl/maps/uS3TjSpYwyb4V72s5"><img className="locationimage" src={mdtwoimage} alt="" /></a>
						<p>Aktuelle schnellste Zeit: {highscores.deB.time} von Team {highscores.deB.name.slice(0,1)}***{highscores.deB.name.slice(-1)}</p>
						<Link className='start-link' to="/owtdm" ><p>Tour B</p></Link>
						
						{/* <p><small>Hilfsmittel notwendig:</small></p>
						<p>Maßband, kleine Münze, Bleistift</p> */}
					</div></>:
					<div className='menu-locationentry'>
					<p>Tour A (english) starts in front of the entrance of the Gruson-Gewächshäuser.</p>
					<a href="https://goo.gl/maps/UYimBmCVp7rJikjdA"><img className="locationimage" src={mdoneimage} alt="" /></a>
					<p>Currently fastest time: {highscores.enA.time} by Team {highscores.enA.name.slice(0,1)}***{highscores.enA.name.slice(-1)}</p>
					<Link className='start-link' to="/neenodm" ><p>Tour A (en)</p></Link>
				</div>}
					{/* {(deutsch)&&<div className='menu-locationentry'>
						<p>Tour A (english) starts in front of the entrance of Gruson-Gewächshäusern.</p>
						<a href="https://goo.gl/maps/UYimBmCVp7rJikjdA"><img className="locationimage" src={mdoneimage} alt="" /></a>
						<p>Currently fastest time: **:**:** by Team ***</p>
						<Link className='start-link' to="/neenodm" ><p>Tour A (en)</p></Link>
					</div>} */}
					
				</div>
				</div>
				

				
			</div>

			
		</div>
		{(deutsch)?<div className='infotext'>
			Aktuell gibt es nur Rätseltouren in Magdeburg. In Zukunft wird es QueenofQuest ggf. in weiteren Städten geben. 
			Die Touren sind sowohl für Touristen, als auch für Alteingesessene geeignet.
		</div>:<div className='infotext'>
			Currently we offer tours only in Magdeburg, but want to expand queenofquest into other cities in the future.
			 The tours are suitable for tourist, as well as well established residents. 
		</div>}
		</>
    )
}
export default MainMenu;
