// getting geolocation if available
// otherwise showing a rotating arrow on top of the map
import React, { useEffect, useState} from 'react';


const MyLocation = ({refresh, setPosition} ) => {
	const[errormsg, setErrorMsg]=useState(false)
	let posiwatcher

	useEffect(()=>{

			if (!navigator.geolocation) {
			  console.log('Geolocation is not supported by your browser');
				setErrorMsg(true)
			} else {
				setErrorMsg(false)
			const options = {
					enableHighAccuracy: true,
					maximumAge: 10000,
					timeout: 10000
				  };
			  console.log('Locating...');
			  /* if(loading){
				const getpositionInterval=setInterval(()=>{ */
					console.log("starting locating thread");
					posiwatcher=navigator.geolocation.watchPosition((newposition) => {
					
					let posi=newposition.coords
					posi.timestamp= newposition.timestamp
					console.log("Position updated:"+posi.longitude+", "+posi.latitude );
					setPosition(posi)
					
					//clearInterval(getpositionInterval)
					//setPosition({timestamp: 1632834152598, latitude: 52.142425, longitude: 11.6576816, altitude: null, accuracy: 14.914, altitudeAccuracy: null, heading: 170, speed: 2.3 })
					}, 
					(error) => {
						setErrorMsg(true)
						switch(error.code) {
							case error.PERMISSION_DENIED:
							  console.log("User denied the request for Geolocation.")
							  break;
							case error.POSITION_UNAVAILABLE:
								console.log("Location information is unavailable.")
							  break;
							case error.TIMEOUT:
								console.log("The request to get user location timed out.")
							  break;
							case error.UNKNOWN_ERROR:
								console.log("An unknown error occurred.")
							  break;
						  }
					},
					options
				)
			/* }, 10000)
			} */
		}
		return ()=>{
			navigator.geolocation.clearWatch(posiwatcher);
		}
	},[refresh])



    return (
		<>
		{errormsg&&<div className='error-container' >
			<div className='error-message'>
			<button className="close" onClick={()=>setErrorMsg(false)}>&times;</button>
				<h2>Fehler!</h2>
				<p>Der Standort konnte nicht ermittelt werden.</p>
				<p>Stelle sicher, dass Standort aktiviert und für die Seite zugelassen ist. Lade die Seite ggf. neu und versuche es erneut.</p>
				<p>Mit Chrome auf einem Android Smartphone funktioniert es der Erfahrung nach am besten.</p>
				<p> Ansonsten muss dieses Rätsel leider übersprungen werden.</p>
			  
			
			</div>
			
		</div>} 
		</>
    )
}
export default MyLocation;