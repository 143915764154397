import React, {useEffect, useState} from 'react';
import Cookies from 'js-cookie'

import Introduction, {Ending, QuestText, QuestImage, QuestPositionBlur, QuestPositionImage, QuestMapCollect, QuestAudio, QuestOptions, QuestMagnifier, QuestDnD} from './QuestFunctions';

import Character from './Character'
import './QuestMapCharacter.css';
import ottowin from '../images/gamescreen/ottowin.png'

import Navbar from './Navbar';


const initstats={time: 0, pausecount: 0, solved: 0,skips: 0, hints: 0, fails:0, points: 0}

const QuestMap = ({position, contentarray}) => {
	const cookienames={
		queststate: contentarray[0].id+"queststate",
		started: contentarray[0].id+"started",
		stats: contentarray[0].id+"stats",
	}

	const [success, setSuccess]=useState(false)
	const [points, setPoints]=useState(0)
	  const [stats, setStats]=useState(()=>{
		  let initState=Cookies.get(cookienames.stats)
		  initState=(initState)?JSON.parse(initState):initstats
		  console.log(initState);
		  return (initState)
	  })
	const [starttime, setStarttime]=useState(0)

	const[queststate, setQuestState]=useState(()=>{
		const initState=parseInt(Cookies.get(cookienames.queststate))
		return (initState)||0})

	const[modalcontent, setModalContent]=useState(0)
 	const[modal, setModal]=useState(false)
	const[started, setStarted]=useState(()=>{
		const initState=Cookies.get(cookienames.started)
		return (initState==='true')})


const activateNext=((how, points)=>{
	let finalpoints=stats.points
	if(how==='solved'){
		setSuccess(true)
		let currentpoints=points*(Math.pow(0.9,stats.hints))*(Math.pow(0.8,stats.fails))
		finalpoints+=currentpoints
		setPoints(currentpoints)
		
		
		setTimeout(()=>{
			setSuccess(false)
		},3000)
	}
	setStats({...stats, fails: 0, hints: 0, points: finalpoints})
	console.log(stats);
	
	setQuestState(queststate=>{
		console.log(queststate);
		return((queststate<contentarray.length+1)?queststate+1:0)})
	
})
const openQuest=((id)=>{
	setModalContent(id)
	setModal(true)

})

const running=((value)=>{

	if(value==="run"){
		if(queststate===0){
			activateNext()
		}
		setStarttime(new Date().getTime()-stats.time*1000)
		setStarted(true)
		console.log(stats);
	}else if(value==="pause"){
		
		if(stats.pausecount<3){
			let pausecount=stats.pausecount+1
			let dtime=parseInt((new Date().getTime()-starttime)/1000)
			setStats({...stats, time: dtime, pausecount: pausecount})
			setStarted(false)
		}
		

	}else if(value==="restart"){
		console.log(cookienames);
		//running(false)
		setQuestState(0)
		setStarted(false)
		setStats(initstats)
		
	}else if(value==="stop"){
		let pausecount=stats.pausecount+1
			let dtime=parseInt((new Date().getTime()-starttime)/1000)
			setStats({...stats, time: dtime, pausecount: pausecount})
			setStarted(false)
		
	}
	
})

useEffect(()=>{
	//!modal&&setTimeout(function() { window.scrollTo(1, '-1px'); }, 100);
	!modal&&window.scrollTo({top: -500, left: 0, behavior: 'smooth'})


},[modal])

useEffect(()=>{
	if(started){
		if(starttime===0){
			setStarttime(new Date().getTime()-stats.time*1000)
		}else{
			let dtime=parseInt((new Date().getTime()-starttime)/1000)
			setStats({...stats, time: dtime})
		}
		
	}
	Cookies.set(cookienames.queststate, queststate, { expires: 30 })
	Cookies.set(cookienames.started, started, { expires: 30 })
},[modal, modalcontent, started, queststate])

useEffect(()=>{
	Cookies.set(cookienames.stats, JSON.stringify(stats), { expires: 30 })
},[stats])


    return (
		<>
		<Navbar running={running} started={started} gamenav/>
		<div className="map-character">
			
			{modal&&<div className="modal" onClick={()=>setModal(false)} >
				
					<div className="modal-content" onClick={(e)=>{e.stopPropagation()}}>
						<button className="close" onClick={()=>setModal(false)}>&times;</button>
						{contentarray.map((content, index)=>{
							if(modalcontent===index){
					
								
							switch(content.type){
								case 'intro':
									return(<Introduction key={"quest"+index} running={running} stats={stats} started={started} setSolved={activateNext} setModal={setModal} content={content}/>)
								case 'text':
									return( started?<QuestText key={"quest"+index} done={(queststate>index)} stats={stats} setStats={setStats} setSolved={activateNext} content={content} />:<p>Pause</p> )
								case 'options':
									return( started?<QuestOptions key={"quest"+index} done={(queststate>index)} stats={stats} setStats={setStats} setSolved={activateNext} content={content} />:<p>Pause</p> )
								case 'image':
									return( started?<QuestImage key={"quest"+index} done={(queststate>index)} stats={stats} setStats={setStats} setSolved={activateNext} content={content} /> :<p>Pause</p>)
								case 'audio':
									return(started?<QuestAudio key={"quest"+index} done={(queststate>index)} stats={stats} setStats={setStats} setSolved={activateNext} content={content} />:<p>Pause</p>)
								case 'mapcollect':
									return(started?<QuestMapCollect key={"quest"+index} done={(queststate>index)} stats={stats} setStats={setStats} setSolved={activateNext} content={content} position={position}/>:<p>Pause</p>)
								case 'positionblur':
									return(started?<QuestPositionBlur key={"quest"+index} done={(queststate>index)} stats={stats} setStats={setStats} setSolved={activateNext} content={content} position={position}/>:<p>Pause</p>)
								case 'positionimage':
									return(started?<QuestPositionImage key={"quest"+index} done={(queststate>index)} stats={stats} setStats={setStats} setSolved={activateNext} content={content} position={position}/>:<p>Pause</p>)
								case 'dnd':
									return(started?<QuestDnD key={"quest"+index} done={(queststate>index)} stats={stats} setStats={setStats} setSolved={activateNext} content={content}/>:<p>Pause</p>)
								case 'magnifier':
									return(started?<QuestMagnifier key={"quest"+index} done={(queststate>index)} stats={stats} setStats={setStats} setSolved={activateNext} content={content}/>:<p>Pause</p>)
								case 'outro':
									return(<Ending key={"quest"+index} done={(queststate>index)} running={running} stats={stats} setSolved={activateNext} setModal={setModal} content={content} />)
								}
								
						}
						})}

						{success&&<div className="win" >
							<img src={ottowin} width="100%" />
							<p >+{parseInt(points)}</p>
							</div>}
				</div>
			</div>}


			<div className='horizontal-scroll'>
				<div  className='horizontal-scroll-components'>
				
					<Character contentarray={contentarray} queststate={queststate} openQuest={openQuest} />
				</div>
				
			</div>

			
	
					</div>
			</>
    )
}
export default QuestMap;

