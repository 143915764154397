import dom from '../images/domMDsat.png'
import otto from '../images/otto.gif'
import leben from '../images/mdquests/leben.png'
import hubbruecke from '../images/mdquests/hubbruecke.png'
import hyparschale from '../images/mdquests/hyparschale.png'
import domplatz from '../images/mdquests/leben.png'
import domplatztwo from '../images/mdquests/leben.png'
import halbkugelrechts from '../images/map/halbkugelmarkerrechts.png'

import errstern from '../images/mdquests/leben.png'


import ifak from '../images/mdquests/lndw/ifak.jpg'
import wissenschaftshafen from '../images/mdquests/lndw/hafen.JPG'
import eggdrop from '../images/mdquests/lndw/eggdrop.jpg'
import induktiv from '../images/mdquests/lndw/induktiv.jpg'
import kran from '../images/mdquests/lndw/kran.jpg'
import antenne from '../images/mdquests/lndw/antenne.jpg'
import satellite from '../images/mdquests/lndw/satellite.jpg'
import water from '../images/mdquests/lndw/water.jpg'
import parkplatz from '../images/mdquests/lndw/parkplatz.png'

import sueden from '../images/mdquests/sueden.png'
import ottonen from '../images/mdquests/ottonen.jpg'
import bodenmarker from '../images/mdquests/bodenmarker.JPG'
import klostermap from '../images/mdquests/klostermap.jpg'

import gaertner from '../audio/gaertner.mp3'
import gaertnerEN from '../audio/gaertnerEN.mp3'


import hubbrueckeEN from '../images/mdquests/hubbrueckeEN.png'
import kestrel from '../images/mdquests/kestrel.png'

import ottomarker from '../images/map/ottomarker.png'

/* 
	Grusons Gewächsehäuser
	Anne Frank Mahnmal
	Sternbrücke
	Schiffsmuseum "Württemberg"
	Stadtpark
	Albinmüller-Turm
	Stadthalle
	Pferdetor
	Hyparschale
	Hubbrücke
	Elbbahnhof
	Elbbalkon/Zeitzähler
	Elbtreppen/MDR Funkhaus/Domfelsen
	Schleinufer/Bastion Cleve/Milchkuranstalt
	Wehrturm Hinter der Möllenvogtei
	Remtergang/Dom
	Domplatz
	Hundertwasserhaus
	Kloster
*/
const mdLndw={
	intro: {
		id: "mdlndw",
		icon: ottomarker,
		position: [52.14176645732292, 11.656368415817148],
		bounds: [[52.146042662212864, 11.650421018385043], [52.13514977901509, 11.665168047953555]],
		heading: "Willkommen zu Otto's Tour bei der Langen Nacht der Wissenschaft.",
		info: ""
	},
		outro: {
		id: "mdlndw",
		position: [52.14243469450437, 11.657745622165416],
		cliffhanger: "Sehr gut gemacht. Falls ihr nochmal Lust auf eine Tour mit noch anderen Rätseln habt, probiert gerne eine von Otto's anderen Rätseltouren aus."
	},
	quests:[

		{
			type: "options",
			points: 70,
			position: [52.14176645732292, 11.656368415817148],
			quest: "Wieviele Tonnen an Getreide, Zucker und Kali wurden im ehemaligen Handelhafen umgeschlagen?",
			image: wissenschaftshafen,
			tipps: [],
			options: ["10.000t", "82.000.000t", "1.400.000.000t"],
			solution: [false,false,true],
			info: "Bevor der Hafen zum Wissenschaftshafen umgebaut wurde, war der Ende des 19. Jahrhunderts erbaute Handelshafen in seiner Blütezeit einer der modernsten Binnenhäfen Deutschlands."
		},
		{
			type: "text",
			points: 80,
			position: [52.14207577024839, 11.657023656528871],
			image: ifak,
			quest: "Die typische Architektur lässt es erahnen. Doch was war die Denkfabrik, in die das ifak 2009 eingezogen ist, vorher?'",
			tipps: ["Mäuse hätten hier früher bestimmt gerne gewohnt.", "Heute wird hier Wissen gespeichert, früher eher Körner."],
			solution: ["Getreidesilo", "Ein Getreidesilo", "Getreidespeicher", "silo"],
			info: "Das ehemalige Getreidesilo wird jetzt hauptsachlich für Forschungsunternehmen genutzt."
		},
		{
			type: "text",
			points: 80,
			position: [52.1427852481217, 11.657561954629612],
			image: kran,
			quest: "Wieviele Meter hoch ist der graue Kran im Wissenschaftshafen, wenn er vollständig ausgefahren ist?'",
			tipps: ["Mäuse hätten hier früher bestimmt gerne gewohnt.", "Heute wird hier Wissen gespeichert, früher eher Körner."],
			solution: ["40", "vierzig", "40m", "40 meter"],
			info: ""
		},{
			type: "options",
			points: 70,
			position: [52.14260063461509, 11.657708543608221],
			image: parkplatz,
			quest: "Wie heißt die Künstliche Intelligenz, das zur Erkennunge von geparkten Fahrzeigen und anderen Objekten eingesetzt wird?",
			tipps: [],
			options: ["ALEXA","RetinaNet", "YOLO"],
			solution: [false, false, true],
			info: "YOLO kann dabei helfen die Belegung von Parkplätzen zu erkennen."
		},{
			type: "options",
			points: 70,
			position: [52.14260063461509, 11.657708543608221],
			image: satellite,
			quest: "Wie heißt das europäische Satellitensystem für Navigation und Ortung?",
			tipps: [],
			options: ["Kopernikus","Galileo", "Giodarno", "Kepler"],
			solution: [false, true, false, false],
			info: "Die bis zu 30 zum System gehörigen Satelliten kreisen in einer Höhe von ca. 23.260km mit einer Geschwindigkeit von 3,6km/s um die Erde."
		},{
			type: "options",
			points: 70,
			position: [52.14261738883218, 11.657824570376064],
			image: induktiv,
			quest: "Wie hoch ist der typische Wirkungsgrad kabelloser (induktiver) Ladesysteme?",
			tipps: [],
			options: ["50%", "75%","90%"],
			solution: [false, false, true],
			info: "Der Wirkungsgrad induktiver Ladesysteme ist von vielen Faktoren abhängig und liegt in der Praxis heutzutage schon oft über 90%."
		},{
			type: "options",
			points: 70,
			position: [52.14251267487205, 11.657870071069338],
			image: eggdrop,
			quest: "Auf welchem Newtonschen Gesetz beruht unser Kinderexperiment \"Faule Eier\"",
			tipps: [],
			options: ["1. NG - Trägheitsgesetz", "2.NG - Aktionsprinzip/Impulserhaltung", "3.NG - Reaktions-/Wechselwirkungsprinzip"],
			solution: [true, false, false],
			info: "Das 1. Newtonsche Gesetz \"Wirkt auf einen Körper keine Kraft, so ist seine Geschwindigkeit zeitlich konstant.\" wird auch Trägheitsgesetz genannt."
		},{
			type: "options",
			points: 70,
			position: [52.14248754348499, 11.657942872178573],
			image: antenne,
			quest: "Worauf muss man bei Antennen für beide Endstellen - neben der richtigen Arbeitsfrequenz - achten, damit die Funkverbindung gut funktioniert?",
			tipps: [],
			options: ["Beide Seiten haben gleiche Orientierung", "Beide Antennen vom gleichen Hersteller", "Beide Antennen sind gleich groß"],
			solution: [true, false, false],
			info: "Die Orientierung, also die Polarisierung von beiden Antennen muss gleich sein. Beispielsweise lässt sich eine vertikal ausgesendete Welle nicht gut mit einer horizontal ausgerichteten Antenne empfangen."
		},{
			type: "options",
			points: 70,
			position: [52.142465204462354, 11.658022498391801],
			image: water,
			quest: "Was ist das sogennante Fremdwasser im Kanalnetz?",
			tipps: [],
			options: ["Schmutzwasser in Tourismus-Regionen","Regenwasser","Einleitungen von Industriebetrieben","Infiltriertes Grundwasser"],
			solution: [false, false, false, true],
			info: "Durch Fremdwasser werden die Kapazität von Abwasserleitungen und Kläranlagen beansprucht und sorgen so für unnötige ökologische und ökonomische Belastung."
		},

	]
}
const mdOne={
	intro: {
	id: "mdone",
	icon: ottomarker,
	position: [52.11402417984451, 11.631301887115065],
	bounds: [[52.109087053201506, 11.624900219702923], [52.13629751946341, 11.654885895878001]],
	heading: "Willkommen zu Otto's erster Tour.",
	info: "13"
},
	outro: {
	id: "mdone",
	position: [52.125657, 11.634938],
	cliffhanger: "Eine Sache fehlt eigentlich noch. Wisst ihr was? Vielleicht finden wir es bei der nächsten Tour."
},
	quests:[
	{
		type: "audio",
		points: 70,
		quest: "",
		position: [52.11353810002772, 11.631291964344259],
		image: otto,
		audio: gaertner,
		tipps: ["Er schaut recht steinern aus","und besteht aus drei Wörtern"],
		solution: ["Peter Joseph Lenné", "Peter Joseph Lenne"],
		info: "Der harte Kopf gehört zu Peter Joseph Lenné. Er war ein preußischer Gartenkünstler und General-Gartendirektor der königlich-preußischen Gärten."
	},
	{
		type: "mapcollect",
		points: 40,
		position: [52.113525341097834, 11.631782167479072],
		quest: "Ich hab hier im Park was von mir verloren. Kannst du mir helfen, es einzusammeln?",
		tipps: ["Wenn du Otto nicht gesehen hast, funktioniert evtl. deine Standorterkennung nicht richtig. (siehe FAQ)", "Du kannst dich hier auf der Karte auch umsehen."],
		positions: [[52.11394036209371, 11.636669647801677], [52.11602228108212, 11.634910118819517]],
		targetposition:[52.114591, 11.636303],
		startposition: [52.11366195591674, 11.63177334117796],
		bounds: [[52.111528904020915, 11.629245293803788], [52.1171554366302, 11.6383]],
		targetmarker: halbkugelrechts,
		info: "Danke, damit haben wir schonmal eine Halbkugel für mein Experiment."
	},
	{
		type: "text",
		points: 80,
		position: [52.114591, 11.636303],
		quest: "Nun geht es den Weg hinauf zur Überquerung. Oben angekommen stellt sich ein Rätsel. 'Reist man so schnell wie maximal möglich, erreicht man ihn in gut 8 Minuten. Wie oft wurde er hier verbaut?'",
		tipps: ["Was ist die höchste Geschwindigkeit?", "Er wird häufig mit Zacken dargestellt"],
		solution: ["1200", "eintausendzweihundert", "tausendzweihundert"],
		info: "1200 Sterne. Unser nähster Stern ist die Sonne. Das Licht braucht von dort etwa 8:20min für die 150 Millionen km bis zu uns. Zum Mond sind es nur 1,3s. Ganz so weit ist die Tour heute aber nicht."
	},
	{
		type: "text",
		points: 50,
		position: [52.116542102914025, 11.634876717609187],
		quest: "Überquert das Nass und geht zu ihm, der jetzt im Alter trocken liegt. Sein Typ hilft euch wohin es weiter geht. (10,22,15,16,,10,o,11,21,4,10)",
		tipps: ["Elbeseitenradschleppdampfer", "3=b"],	
		solution: ["nachnorden", "nach norden"],
		link: "http://www.sd-wuerttemberg.de/geschichte/",
		info: "Nun weiter nach Norden, wie auch der Schleppdampfer früher. Er verkehrte auf einer Fahrtstrecke von 657 km zwischen Hamburg und Usti nad Labem (CSFR), legte dabei etwa 800000km zurück. "
	},
	{
		type: "positionscale",
		points: 80,
		position: [52.11734207950953, 11.638825244168354],
		maxdistance:320,
		quest: "Der Empfang ist schlecht, vielleicht müsst ihr etwas weiter gehen?",
		tipps: ["Mit ihm lassen sich Taschenmonster ganz gut fangen.", "Sie umhüllt Obst und Gemüse."],
		solution: ["Hyparschale", "Hyperschale"],
		targetposition: [52.120050758117685, 11.638485540115553],
		image: hyparschale,
		info: "Du stehst jetzt bald vor der HypArschale aus 1969. Die Stahlbetondecke ist selbsttragend und besteht aus vier hyperbolischen Paraboloiden. Das sind quasi vier 3D Parabeln."
	},
	{
		type: "positionscale",
		points: 80,
		position: [52.119505, 11.638615],
		maxdistance: 100,
		quest: "Schon wieder schlechter Empfang?",
		tipps: ["Ersteres ist ein Indikator für die Leistungsfähigkeit eines Verbrennungsmotors",  "Zweiteres hilft beim Erinnern"],
		solution: ["Hubbrücke", "Hubbruecke"],
		targetposition: [52.120895, 11.638271],
		image: hubbruecke,
		info: "Die Hubbrücke von 1848 wurde 1998 für den Bahnverkehr stillgelegt. Jetzt können wir hier rüber laufen. Auf der anderen Seite gehts dann nach rechts..."
	},
	{
		type: "text",
		points: 75,
		position: [52.120895, 11.638271],
		quest: "Gehe zur Zeit. Die eine ist offensichtlich, doch in welchem weit entfernten Land entspringt ihr gegenüber?",
		tipps: ["Offensichtlich sitzt auf der Kugel", "Gegenüber liegt fast unter der Kugel"],
		solution: ["China"],
		info: "Der Jangtsekiang, auch Jangtse, ist der längste Fluss Chinas und der drittlängste der Welt."
	},
	{
		type: "text",
		points: 60,
		position: [52.12065771508051, 11.635109027297377],
		quest: "Weiter entlang der Elbe. Eine antike Hauptstadt befindet sich weit von ihr entfernt, doch ihr Name ist recht ähnlich.",
		tipps: ["Sie liegt nicht in Deutschland.", "Und hinter der Elbmündung."],
		solution: ["Röm", "Roem", "Rømø"],
		info: "Rømø deutsch Röm ist die südlichste dänische Wattenmeerinsel."
	},
	{
		type: "text",
		points: 80,
		position: [52.12164953241813, 11.635658629934095],
		quest: "Die nächste Herausforderung liegt zwischen den Stufen. Die einen Zahlen sind offensichtlich, doch welche Zahl ist in der Nähe eingeprägt?",
		tipps: ["Sie ist nicht am gleichem Gebäude.", "Was ist mit der Rampe?"],
		solution: ["2007"],
		info: "2007 war hier allerdings kein Hochwasser. Nun hinauf und über den lauten Strom, hin zur Bastion!"
	},
	{
		type: "text",
		points: 85,
		position: [52.12282325221644, 11.636131766131507],
		quest: "Zwei Namen verschmolzen hier zu einem. Welcher ging dabei verloren?",
		tipps: ["Es geschah im Jahre 1709", "Heute heißt sie nur Cleve", "Der englische Text ist etwas eindeutiger."],
		solution: ["Gebhardt", "Gebhart", "Gebhard"],
		info: "Die Bastion Cleve ist der südöstliche Abschluss der ehemaligen Festung Magdeburg und wurde 1709 mit dem Rondell Gebhardt verbunden."
	},
	{
		type: "text",
		points: 60,
		position: [52.12349812710087, 11.63552481362903],
		quest: "Jetzt ein kleines Stück Richtung Nordosten, und dann abbiegen durch das Haus hindurch. Wer hat früher in der Hausnummer 1 gewaltet?",
		tipps: ["Klingt fast wie ein Rentnerweg.", "Zwischen 1690 und 1807 war hier sein Amtssitz."],
		solution: ["Domsyndikus", "Syndikus"],
		info: "Der Domsyndikus (Syndikus: altgriechisch: Verwalter einer Angelegenheit) war für die Rechtsgeschäfte zuständig."
	},
	{
		type: "text",
		points: 45,
		position: [52.12456128729267, 11.6356753581112],
		quest: "Weiter geht's zum großen Platz. Vorne befindet sich eine metallische Karte. Wie lautet die Summe unseres großen Flusses?",
		tipps: ["Finde die Elbe", "Addiere die Punkte"],
		solution: ["9", "neun"],
		info: "9. Brailleschrift besitzt keine Großbuchstaben. Um Großbuchstaben darzustellen, wird ein Steuerzeichen vorgesetzt. Vielleicht merkt ihr euch die Zeichen..."
	},
	{
		type: "text",
		points: 60,
		position: [52.12502870556812, 11.63527624990168],
		quest: "Um heutzutage in den Dom zu gelangen, müsst ihr an welchen kleinen Wesen vorbei?",
		tipps: ["Ihr könnt es auch herausfinden, wenn die Nebentür verschlossen ist.","Sie können mehr als nur laufen."],
		solution: ["Vögeln", "Vogel", "Vögel", "Taube", "Tauben"],
		info: "Heinrich Apel (1935-2020) war Restaurator und Bildhauer und hat unter anderem diese Türklinken erschaffen."
	},
	
	{
		type: "positionscale",
		points: 85,
		position: [52.12492308302743, 11.634229564133236],
		maxdistance: 140,
		quest: "Die Tour neigt sich dem Ende zu. Könnt ihr das vorerst letzte Rätsel auch noch lösen?",
		tipps: ["Mit Abstand zu Gebäuden verbessert es sich oft.", "Es gibt Ähnlichkeiten mit Bekanntem..."],
		solution: ["Leben"],
		targetposition: [52.125657, 11.634938],
		image: leben,
		info: "An der Elbe in Magdeburg lässt es sich gut LEBEN. Mal schauen, wie gut ihr abgeschnitten habt..."
	},
	]
}
const mdTwo={
	intro: {
	id: "mdtwo",
	icon: ottomarker,
	position: [52.11402417984451, 11.631301887115065],
	bounds: [[52.109087053201506, 11.624900219702923], [52.13629751946341, 11.654885895878001]],
	heading: "Willkommen zu Otto's erster Tour.",
	info: "13"
},
	outro: {
	id: "mdtwo",
	position: [52.125657, 11.634938],

	cliffhanger: ""

},
	quests:[
	
	{
		type: "text",
		points: 50,
		position: [52.126182299981195, 11.635829695788953],
		quest: "Findet den Weg der Exekutive und geht an der Gabelung den Berg hinab. Hinter dem Nagetier geht es durch einen Bogen. Was wurde hier aus Bombensplittern geschaffen?",
		tipps: ["Hinter dem Tor geht's weiter."],
		solution: ["Vogel"],
		info: "Der Skulpturenpark ist seit 1989 in der Klosteranlage angelegt worden."
	},
	{
		type: "text",
		points: 50,
		position: [52.12645359009568, 11.6370688494413],
		quest: "Ein weiteres Kunstwerk blickt auf die Elbe. Um es zu finden, müsst ihr erst Richtung Fluss und dann hinauf auf die adelige Mauer. Wer ist es?",
		tipps: ["Geht erst bergab und dann einige Treppen hinauf."],
		solution: ["mechthild", "mechthild von magdeburg"],
		info: "Mechthild von Magdeburg war eine christliche Mystikerin. Die Lichtdurchlässigkeit der Skulptur stehe für die immateriellen und transzendenten Themen der Texte Mechthilds und den Wunsch, die Seele vom Körper zu befreien."
	},
	{
		type: "text",
		points: 50,
		position: [52.12577484451446, 11.637970126871716],
		quest: "Dort wo die 1434 weht hängt etwas, was dort nicht so richtig hingehört. Welches ist das erste Zeichen des lateinischen Alphabets, dass dort zu finden ist?",
		tipps: ["Dort befindet sich auch ein Herz."],
		solution: ["m"],
		info: "Am Fuße des Wehrturms 'Kiek in de Köken' befand sich früher die Elbe, bis das heutige Schleinufer aufgeschüttet wurde. Auf Hochdeutsch bedeutet der Name 'Guck in die Küche', weil man wohl in die Küche des benachbarten Erzbischofs blicken konnte."
	},
	{
		type: "text",
		points: 50,
		position: [52.12459990797313, 11.63705439808043],
		quest: "Auf der anderen Seite des Walls geht eine Wendeltreppe hinab. Welche kopflose Sängerin steht hier zweimal?",
		tipps: ["Die Treppe liegt in Richtung Dom."],
		solution: ["madonna"],
		info: "Vielleicht geht es bei der Madonna auch nicht um die Sängerin. Ihr seid jetzt im Garten der Möllenvogtei - eine der ältesten Gartenanlagen der Stadt."
	},
	{
		type: "text",
		points: 70,
		position: [52.12515156221887, 11.637078774573377],
		quest: "Man kommt auch durch ein altes Stadttor in den Garten der Möllenvogtei. Aus welchem Jahr stammt das einzige noch erhaltene?",
		tipps: ["Ihr findet es in Richtung Dom"],
		options: ["1474", "1501", "1493", "1527"],
		solution: ["1493", "1495"],
		info: "Das Tor wurde 1493 erbaut (manche sagen 1495) und wurde unter anderem als Zugang zum erzbischöflichen Elbhafen genutzt."
	},
	{
		type: "magnifier",
		points: 80,
		position: [52.124661947708454, 11.636159164418162],
		quest: "Gehe zum Ort woher dieses Bild stammt. Ihr befindet euch jetzt auf dem Weg der ...?",
		tipps: ["Richtung Süden durch den Remtergang.", "Die Straße ist 'Am Dom'.", "Findet die erste Steintafel."],
		image: ottonen,
		solution: ["Ottonen"],
		info: "Hier liegt der Weg der Ottonen. Die Liudolfinger herrschten im ostfränkisch-deutschen Reich zwischen 919 und 1024. Die drei Kaiser Otto I., II. III. sorgten für die Bezeichung der Ottonen."
	},
	
	{
		type: "text",
		points: 40,
		position: [52.12376526344465, 11.635265402433928],
		quest: "Jetzt ist ein bisschen Mathematik gefordert. Welches Oberhaupt wurde am Ältesten?",
		tipps: ["Die Steinplatten geben euch Auskunft."],
		solution: ["adelheid", "Adelheit"],
		info: "Wirklich hoch war die Lebenserwartung damals nicht. Adelheid von Burgund war von 947 bis 950 Königin von Italien, dann von 951 bis 973 ostfränkische Königin und wieder Königin von Italien sowie von 962 bis 973 Kaiserin des ostfränkisch-deutschen Reiches."
	},
	{
		type: "text",
		points: 60,
		position: [52.1239286391644, 11.634534014426617],
		quest: "Hinterm zweiten Heinrich führt links eine Prachtstraße entlang des Parks. Folgt ihr bis zum fünfköpfigen Erheller. Am Haus der Lehre begrüßt euch neben Sophokles wer?",
		tipps: ["Am Domgymnasium befinden zwei Kopfplastiken.", "Auf der Infotafel könnt ihr auch beide Namen finden."],
		solution: ["Homer"],
		info: "Homer ging zwar nicht auf das Domgymnasium, dafür allerdings Martin Luther. (Zumindest zum Vorgänger - der Domschule). Geht nun weiter die Straße entlang."
	},
	{
		type: "searchimage",
		points: 70,
		position: [52.12245596171834, 11.632639975634769],
		quest: "Hiervon gibt es einige auf dem Weg, also schaut genau hin. Wenn ihr in gefunden habt, biegt ihr dort links in welche Straße ein?",
		tipps: ["Ihr könnt auch ins Bild zoomen.","Sein Vorname lautet Johannes.", "Er war bekannter Physiker."],
		image: bodenmarker,
		solution: ["kepler", "keppler", "keplerstraße", "keplerstrasse"],
		info: "Kepler bestätigte Galileos Entdeckungen, dass sich die Planeten um die Sonne drehten. Nun geht es gerade aus Richtung Elbe."
	},
	{
		type: "text",
		points: 30,
		position: [52.12152699587792, 11.632159430676525],
		quest: "Ein Stück weiter findet ihr eine kleine Grünfläche. Welchen Wesen dient sie?",
		tipps: ["Maya ist eine bekannte Vertreterin ihrer Gattung", "Haben was mit Honig zu tun."],
		solution: ["Bienen", "Biene"],
		info: "Für ein Kilogramm Honig muss ein Bienenvolk insgesamt ca. 100.000km zurücklegen."
	},
	{
		type: "text",
		points: 80,
		position: [52.1206222452893, 11.634840658304398],
		quest: "Im Dunkeln kann man vom Elbbalkon aus einen leutenden Spruch erkennen. Wenn es hell ist, muss man bisschen woanders gucken, um ihn zu lesen. Wie lautet der erste Teil?",
		tipps: ["Neben dem gläsernen Balkon kann man vielleicht was finden.","Er gehört zu 'von hier aus noch viel weiter'"],
		solution: ["von so weit her bis hier hin","von so weit her bis hier hin ", "vonsoweitherbishierhin"],
		info: "Nachts erleuchtet der Spruch: 'VON SO WEIT HER BIS HIER HIN' die Nordseite der ehemaligen Eisenbahn Hubbrücke. Nun auf zur Bahnkreuzung."
	},
	
	{
		type: "positionscale",
		points: 60,
		position: [52.12054368143498, 11.635323872075439],
		maxdistance: 185,
		quest: "Das  Bild ist noch ziemlich klein... Der Weg mit der niedrigsten Luftdichte vergrößert das Bild hoffentlich etwas. Wie lautet das Lösungswort?",
		tipps: ["Weiter oben erkennt man mehr.", "..."],
		solution: ["Süden", "Süd"],
		targetposition: [52.1178557405355, 11.63483426704731],
		image: sueden,
		info: "Weiter in den Süden. Noch ein Stück und dann liegt im Westen der Kavalier I 'Scharnhorst'. Er wurde zwischen 1870 und 1873 gebaut und ist Teil der Magdeburger Festungsanlangen. Er diente dem Schutz der Eisenbahnlinie, wurde zeitweise aber auch als Gefangenenlager genutzt."
	},
	{
		type: "text",
		points: 70,
		position: [52.118389805500136, 11.634738910294589],
		//quest: "Hier hängen einige, aber ich bin etwas anders. Gib 'Ottonen' an dem anderen Ort ein und du erhältst ein Lösungswort, was euch dem Ziel näher bringt.",
		quest: "Hier hängen einige, aber ich bin etwas anders. Woraus bin ich gemacht?",
		//tipps: ["Ich kann Türen schließen.", "Es gibt eine Vor-und Rückseite", "Aber ich wurde aus einem Baum geformt.", "Falls das hölzerne Schloss verschwunden sein sollte, bitte bei mir melden und:....de/toax"],
		tipps: ["Ich kann Türen schließen.", "Ich bin nicht aus Metall."],
		solution: ["Holz", "Fichte", "Tanne"],
		//solution: ["Stromelbe"],
		info: "Fun Fact: Die Zugfestigkeit von Bauholz ist auf sein Gewicht bezogen deutlich höher, als die von Baustahl. Weiter geht's auf der anderen Straßenseite."
		//info: "Die Stromelbe ist der heute größte Elbarm in Magdeburg. Der frühere Hauptstrom war hingegen die Mittelelbe, bis diese verlandete."
	},
	{
		type: "text",
		points: 50,
		position: [52.116609201046515, 11.634727192534598],
		quest: "Wie lautet der steinerne Spruch von Magdeburg?",
		tipps: ["Hier liegen einige Tafeln am Straßenrand."],
		solution: ["Leben an und mit der Elbe"],
		info: "Gab schon bessere Sprüche... Weiter geht's."
	},
	{
		type: "text",
		points: 60,
		position: [52.11640976781352, 11.633709549961447],
		quest: "Langsam neigt sich die Tour dem Ende zu... Etwas niedriger gibt es Sechs, die manchmal Wasser spucken.",
		tipps: ["Links vor der Brücke Richtung Sonnenuntergang führt eine Treppe bergab."],
		solution: ["Frösche", "Frosch"],
		info: "Frösche haben keine Muskeln zum Schlucken. Sie nutzen ihre Augen(+ Muskeln), um ihr Futter weiter in den Rachen zu schieben."
	},
	{
		type: "positionimage",
		points: 80,
		position: [52.11604152946992, 11.63246590162408],
		quest: "Also letzte Aufgabe müsst ihr nur noch das Ziel finden.",
		tipps: ["Vielleicht hilft euch Maps"],
		targetposition: [52.114098383369544, 11.633535397693343],
		maxdistance: 300,
		image: klostermap,
		info: "Der Klosterberggarten war der erste Volksgarten Deutschlands und liegt auf dem Grund des ehemaligem Kloster Berge."
	}
	]
}

const mdThree=[
	
	{	type: "intro",
		id: "mdthree",
		points: 100,
		position: [],
		heading: "Willkommen zu Otto's erster Nachttour.",
		info: "13 Rätsel warten darauf gelöst zu werden."
	},
	{
		type: "positionimage",
		points: 100,
		position: [],
		quest: "Finde Otto und geh zu ihm.",
		tipps: ["Schon im Nordwesten geguckt?"],
		targetposition: [52.126345, 11.634506],
		maxdistance: 120,
		image: domplatztwo,
		info: "Gut, ihr steht jetzt vor der Grünen Zitadelle. Zu meiner Zeit stand hier allerdings noch die Sankt Nikolai Kirche."
	},	
	{
		type: "text",
		points: 100,
		position: [],
		quest: "Wie lautet der Vorname, des Künstlers, der grünen Zitadelle entwarf?",
		tipps: ["Hier gibt es scheinbar einige verschiedene Netzwerke."],
		solution: ["Friedensreich"],
		info: "Die Grüne Zitadelle ist Friedensreich Regentag Dunkelbunt Hundertwasser letztes Projekt. Der Name des Bauwerks kommt von der grünen Bewachsung."
	},
	{
		type: "text",
		points: 100,
		position: [],
		quest: "Im inneren Freiraum sprüht manchmal Wasser. Wie oft ist die auffällige Farbe in der Umrandung verbaut?",
		tipps: ["Die Farbe der Sonne"],
		solution: ["5", "fünf"],
		info: "Für Mieter gilt das 'Fensterrecht', sie dürfen die Fassade um ihre Fenster herum selbst gestalten. (Soweit sie mit ihrem Arm kommen)"
	},
	{
		type: "text",
		points: 100,
		position: [],
		quest: "Verlasst das Haus Richtung Norden und biegt dann ab, Richtung Sonnenaufgang. Von 4 Pfeilern umzäunt findet ihr eine Karte. Bis wann galt diese?",
		tipps: ["Die Farbe der Sonne"],
		solution: ["1631", "1637"],
		info: ""
	},
	{
		type: "text",
		points: 100,
		position: [],
		quest: "Auf dem Weg zur Probstei findet ihr eine Raubkatze. Welches weiter Kunstwerk aus der Natur findet man hier?",
		tipps: ["Bienen nutzen es.","Es befindet sich nicht auf Augenhöhe."],
		solution: ["Nest"],
		info: "In der Probstei (heute Propstei) wohnte der Propst (Vorgesetzter) des Klosters Unser Lieben Frauen."
	},
	{	
		type: "outro",
		position: [],
		cliffhanger: "",
		},
]

const test=[
	
	{
		type: "text",
		quest: "frage",
		tippOne: "Tipp",
		tippTwo: "Tipp",
		solution: ["antwort"],
		info: "Lösungstext"
	},
	{
		type: "dnd",
		points: 100,
		quest: "Welche Reihenfolge stimmt?",
		tipps: [],
		images: [sueden, sueden, sueden, sueden],
		solution: [1,2,3,4],
		info: "Danke, zu meiner Zeit gabs die Grüne Zitadelle noch nicht..."
	},
	{
		type: "Aufgabe",
		quest: "frage'",
		tippOne: "Tipp",
		tippTwo: "Tipp",
		solution: "antwort",
		targetposition: [52.148813, 11.659141],
		image: dom,
		info: "Dies ist der Lösungstext"
	},
	{
		type: "Aufgabe",
		quest: "frage",
		tippOne: "Tipp",
		tippTwo: "Tipp2",
		solution: "antwort",
		image: otto,
		audio: gaertner,
		info: "Dies ist der Lösungstext"
	},
	{
		type: "positionwimmel",
		quest: "Finde Otto und gehe zu ihm",
		tippOne: "Augen auf.",
		tippTwo: "Vielleicht im Nordosten?",
		targetposition: [52.126188, 11.635820],
		image: domplatz,
		info: "Der Domplatz wurde früher auch Neuer Markt genannt."
	},
	{
		type: "positioncollect",
		quest: "Jetzt wird's vielleicht ein bisschen anstrengender...",
		tippOne: "Sammle gelbe Lichter",
		tippTwo: "Meide die roten Bereiche",
		solution: 5,
		image: domplatz,
		info: "Der Domplatz wurde früher auch Neuer Markt genannt."
	},
	{
		type: "positionlab",
		quest: "Finde durch...",
		tippOne: "Augen auf.",
		tippTwo: "Vielleicht im Nordosten?",
		
		targetposition: [52.126188, 11.635820],
		image: domplatz,
		info: "Der Domplatz wurde früher auch Neuer Markt genannt."
	},
	{
		type: "positionlab",
		quest: "Finde durch...",
		tippOne: "Augen auf.",
		tippTwo: "Vielleicht im Nordosten?",
		
		targetposition: [52.126188, 11.635820],
		image: domplatz,
		info: "Der Domplatz wurde früher auch Neuer Markt genannt."
	},
]


const mdOneEN={
	intro: {
	id: "mdoneEN",
	icon: ottomarker,
	position: [52.11402417984451, 11.631301887115065],
	bounds: [[52.109087053201506, 11.624900219702923], [52.13629751946341, 11.654885895878001]],
	heading: "Welcome to Otto's first tour.",
	info: ""
},
	outro: {
	id: "mdoneEN",
	position: [52.125657, 11.634938],
	cliffhanger: ""
},
	quests:[

	{
		type: "audio",
		points: 70,
		quest: "",
		position: [52.11353810002772, 11.631291964344259],
		image: otto,
		audio: gaertnerEN,
		tipps: ["Whose hard head greets you here?","Three words."],
		solution: ["Peter Joseph Lenné", "Peter Joseph Lenne"],
		info: "The solid head here belongs to Peter Joseph Lenné. He was a prussian Gardenarchitect and general-gardendirector of the royal-prussian gardens."
	},
	{
		type: "mapcollect",
		points: 40,
		position: [52.113525341097834, 11.631782167479072],
		quest: "I lost something in the park. Can you help me collect it?",
		tipps: ["If you can't see Otto on the map, your phone can't access its location. (more in FAQ)", "You can pan the map."],
		positions: [[52.11394036209371, 11.636669647801677], [52.11602228108212, 11.634910118819517]],
		targetposition:[52.114591, 11.636303],
		startposition: [52.11366195591674, 11.63177334117796],
		bounds: [[52.111528904020915, 11.629245293803788], [52.1171554366302, 11.6383]],
		targetmarker: halbkugelrechts,
		info: "Thanks, now we have the first half or my experiment."
	},
	{
		type: "text",
		points: 80,
		position: [52.114591, 11.636303],
		quest: "Now we go up towards the river crossing. At the top you can find a clue for the following riddle: 'If you travel as fast as absolutely possible, you will reach it in a bit more than 8 Minutes. How often was it placed here?'",
		tipps: ["What is the highest velocity?", "You can see one in the day, but many more at night"],
		solution: ["1200", "onethousandtwohundred"],
		info: "1200 Stars. Our closest Star is the sun. Light takes about 8:20min to travel the 150 million km to us on earth. To the moon it only takes 1,3s."
	},
	{
		type: "text",
		points: 50,
		position: [52.116542102914025, 11.634876717609187],
		quest: "Cross the river and visit who was once floating on water. His type gives you the key for you next direction. (u,19,,10,o,11,8,16)",
		tipps: ["Elbeseitenradschleppdampfer", "3=b"],	
		solution: ["up north", "upnorth"],
		link: "http://www.sd-wuerttemberg.de/geschichte/",
		info: "Now go up north along the river. The Elbeseitenradschleppdamper (elbe side paddle tug steamer) once traveled between Hamburg und Usti nad Labem (CZ) for a total distance in his lifetime of about 800000km. "
	},
	{
		type: "text",
		points: 80,
		position: [52.11734207950953, 11.638825244168354],
		quest: "What is the superlative of the Superbowl? Translate the second part to german and you get the name of a building. What is it called?",
		tipps: ["The building is close.", "Bowl=Schale" , "*y*** > super"],
		solution: ["Hyparschale", "Hyperschale"],
		info: "You are standing near the 'HypArschale' from 1969. The reinforced concrete ceiling is self-supporting and consists of four hyperbolic parabolas (3D parabolas)."
	},
	{
		type: "positionscale",
		points: 80,
		position: [52.119505, 11.638615],
		maxdistance: 100,
		quest: "Looks like you have bad reception. Maybe walk a bit further.",
		tipps: ["The first part of the word is a central part of a bike wheel.",  "The second is the german word for whatever the drawing is supposed to be."],
		solution: ["Hubbrücke", "Hubbruecke"],
		targetposition: [52.120895, 11.638271],
		image: hubbrueckeEN,
		info: "The lift bridge from 1848 was closed to rail traffic in 1998. Now you can cross it by foot and turn right."
	},
	{
		type: "text",
		points: 75,
		position: [52.120895, 11.638271],
		quest: "Go to the time sphere. One is obvious, but in what distant land is the source of its opposite?",
		tipps: ["Obvious sits on the sphere", "Opposite is almost below the sphere"],
		solution: ["China"],
		info: "The Yangtze River (Jangtsekiang), also known as the Yangtze, is the longest river in China and the third longest in the world."
	},
	{
		type: "text",
		points: 60,
		position: [52.12065771508051, 11.635109027297377],
		quest: "Further along the Elbe. An ancient capital is located far from it, but its name is quite similar.",
		tipps: ["It's not in Germany.", "And beyond the Elbe mouth."],
		solution: ["Röm", "Roem", "Rømø"],
		info: "Rømø (german Röm) is the southern most danish Wadden Sea Island."
	},
	{
		type: "text",
		points: 80,
		position: [52.12164953241813, 11.635658629934095],
		quest: "The next challenge lies between the steps. Some numbers are obvious, but which number is imprinted nearby?",
		tipps: ["It's not on the same building", "What about the ramp?"],
		solution: ["2007"],
		info: "Luckily there was no flooding here in 2007. Now up and above the loud stream und towards the bastion!"
	},
	{
		type: "text",
		points: 85,
		position: [52.12282325221644, 11.636131766131507],
		quest: "Two names merged into one. Which one was lost in the process?",
		tipps: ["It happend in 1709", "Today it's just Cleve"],
		solution: ["Gebhardt", "Gebhart", "Gebhard"],
		info: "The bastion Cleve is the southeastern end of the former fortress Magdeburg and was connected in 1709 with the roundel Gebhardt."
	},
	{
		type: "text",
		points: 60,
		position: [52.12349812710087, 11.63552481362903],
		quest: "Now a little bit to the northeast, and then walk through the house. Who used to rule in the house number 1?",
		tipps: ["His office was here between 1690 and 1807."],
		solution: ["Domsyndikus", "Syndikus"],
		info: "The Domsyndikus (Domsyndic/Cathedral Counsel) was responsible for the legal transactions of the Dom."
	},
	{
		type: "text",
		points: 45,
		position: [52.12456128729267, 11.6356753581112],
		quest: "Now to the metallic map on the big square. What is the sum of our river?",
		tipps: ["Find the map on the dome square", "Add the points."],
		solution: ["9", "nine"],
		info: "9. There are no capital letters in braille. To represent caps you have to add a code (dot-6) in front of the word. Maybe remember how elbe looks..."
	},
	{
		type: "text",
		points: 60,
		position: [52.12502870556812, 11.63527624990168],
		quest: "To access the cathedral today you have to get past which small beings?",
		tipps: ["You can see it when the door is closed.","The can do more that just walk."],
		solution: ["Birds", "bird", "dove", "pidgeon", "doves", "pidgeons"],
		info: "Heinrich Apel (1935-2020) was a restorer and sculptor and created, among other things, these door handles."
	},
	
	{
		type: "positionblur",
		points: 85,
		position: [52.12492308302743, 11.634229564133236],
		quest: "The tour slowly comes to an end. Can you solve the last riddle?",
		tipps: ["You can see it best with the biggest distance to buildings."],
		solution: ["Kestrel"],
		targetposition: [52.125657, 11.634938],
		image: kestrel,
		info: "Nice, you made it. If you are lucky you can see a kestrel flying around the cathedral."
	},
	
],
}

export default mdOne;
export {mdOneEN, mdTwo, mdLndw};
