import React from 'react';

import Navbar from './Navbar';

import './MainMenu.css';

const FAQ = () => {


    return (
		<>
		
		<div className="main-menu">
			<Navbar/>
				<div className="menu-text" >
					<h3>FAQ</h3>
					<p>Die Rätsel sind teilweise recht schwierig zu lösen und fordern manchmal ein bisschen spezielleres Allgemeinwissen. </p>
						<p>
						Deshalb gibt es meistens ein paar Tipps und online recherchieren ist auch erlaubt.</p>
						<p>
						<p> Die meisten Rätsel lassen sich durch die genauere Betrachtung der Umgebung lösen.</p>
						Teilweise hilft es, sich aufzuteilen, falls ihr eine Gruppe seid.</p>
						<p>Je nachdem, wie schwierig die Aufgabe ist, gibt es Punkte zu gewinnen. Der Schwierigkeitsgrad wird oben angezeigt. Für Tipps und bei falsche Antworten müsst ihr von den zu erreichenden Punkten ein paar abgeben.</p>
						<p>Ansonsten kann man einzelne Rätsel auch überspringen, wenn man gar nicht weiterkommt, bekommt dafür allerdings auch keine Punkte.</p>
						<p>Für die beste Appansicht könnt ihr sie auch im Browsermenu installieren, bzw. zum Homescreen hinzufügen.</p>
						<p>Alternativ könnt ihr oben in die Mitte auf das Icon drücken, um in den Vollbildmodus zu kommen.</p>
						<p>Der aktuelle Stand wird auf deinem Gerät gespeichert, sodass du die Tour auch problemlos pausieren kannst.</p>
					<p>Am besten funktioniert die App in Chrome. Für einige Rätsel muss dein Standort detektiert werden. Bei Safari musst du unter "Ortungsdienste" den Zugriff von Safari auf den genauen Standort erlauben.</p>
					<p>Für Fragen und Anregungen könnt ihr euch gerne bei mir <a style={{textDecoration: "underline", color: "white"}} href="https://www.instagram.com/queen_of_quest/"> @projectjomjom</a> und per Mail <a href="mailto:mischkejonathan@gmail.com?subject=QueenofQuest">📧</a> melden.</p>
				</div>
				<div>
			</div>

			
		</div>
		</>
    )
}
export default FAQ;
