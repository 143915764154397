import sharebutton from '../images/share_white.png'

const ShareButton = () => {

    return (
		<>			
			<button /* className='share-button' */ onClick={()=>{
				console.log("sharing...");
				console.log(navigator.share);
				if(navigator.share){
					console.log("sharing.2.");
					navigator.share({
						title: 'Teile dein Abenteuer',
						url: 'https://queenofquest.de'
					  }).then(() => {
						console.log('Thanks for sharing!');
					  })
					  .catch(console.error);
					} else {
						console.log("sharing not supported");
				}
				console.log("sharing.3.");
			}}> <img src={sharebutton} className='share-button' width={21} /></button>
	
					</>
    )
}
export default ShareButton;
