import React, { useState, useContext, useEffect } from 'react';
import {
	Link
  } from "react-router-dom";
import Cookies from 'js-cookie'
import LanguageContext from './LanguageContext';

import ShareButton from './ShareButton';
import logo from '../images/global/logo512.png'
import menu from '../images/global/menu256.png'
import pause from '../images/global/pause.png'
import play from '../images/global/play.png'
import restart from '../images/global/restart.png'


import './Navbar.css'
const Navbar = ({running, started,pausecount, gamenav}) => {
	
	const {deutsch, setDeutsch}=useContext(LanguageContext);
	const[sidebar, setSidebar]=useState(false)
	const maxpause=2



    return (
		<>
		<div className="navbar">

				 <img src={logo} alt="" height="48px" onClick={()=>{document.documentElement.requestFullscreen().catch(console.log)}}/>
				 <button className='sidebar-button' onClick={()=>{setSidebar(true)}} ><img className="buttonicon"  src={menu} alt="" height="42px" /></button>
				 {gamenav&&<>
				 {
					 (pausecount<maxpause||!started)? 
					 <>
					{started?
						<button className='running-button' onClick={()=>{running("pause")}} ><img src={pause} className="buttonicon" alt="" height="42px" /></button>:
						<button className='running-button' onClick={()=>{running("run")}} ><img src={play} className="buttonicon" alt="" height="42px" /> </button>
						}
					 </>:
				 <button className='running-button' onClick={()=>{running("restart")}} ><img src={restart} className="buttonicon" alt="" height="42px" /> </button>
				 }
				 </>}
				
				

		</div>
		
		<div className='sidebar' style={{right: (sidebar?0:"-200vw")}} onClick={()=>{setSidebar(false)}} >
			<div className='sidebar-content' style={{right: (sidebar?0:"-200vw"), transform: (sidebar?'skew(0deg, 0deg)':'skew(15deg, 0deg)')}} onClick={()=>{setSidebar(false)}}  >
			<button className='sidebar-button' style={{top: "-9vh", right: "3vw" }} onClick={()=>{setSidebar(false)}} >&times; </button>
					<div className='sidebar-list' style={{transform: (sidebar?'skew(3deg, 0deg)':'skew(15deg, 0deg)')}} onClick={(e)=>{e.stopPropagation()}} >
					<Link  to="/" >{deutsch?"Hauptmenu":"Mainmenu"}</Link>
					<button className='language-button' onClick={()=>{
						Cookies.set('language', !deutsch, { expires: 30 })
						setDeutsch(!deutsch)
						}} >
						{deutsch?<><span className='deutsch'>DE</span><span className='english'>EN</span></>:
						<><span className='english'>DE</span><span className='deutsch'>EN</span></>}
						</button>
					<Link  to="/FAQ" >FAQ</Link>
					<a  href="mailto:mischkejonathan@gmail.com?subject=riddlefeedback">{deutsch?"Kontakt":"Contact"}</a>
					<a  href="https://www.instagram.com/queen_of_quest/">Instagram</a>
					<Link  to="/datenschutz" >{deutsch?"Datenschutz":"Privacy Policy"}</Link>
					<Link  to="/impressum" >{deutsch?"Impressum":"Publisher"}</Link>
					<div  style={{marginTop: "10px"}}><ShareButton/></div>
					</div>
			</div>
					
		</div>
		</>
    )
}
export default Navbar;
