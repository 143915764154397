import React from 'react';

import './QuestMap.css';
const QuestHeader = ({index, content, length}) => {

    return (
			<div className='quest-content'> 
			<div className='levelmarker'>{index}/<small>{length}</small></div>
				<div className='pointmarker'>
					{content.points}
				</div>
				<h4>{content.quest}</h4>
			</div>
					
    )
}

export default QuestHeader;