import React, {useState, useEffect} from 'react';
import Cookies from 'js-cookie'
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import { useMediaQuery } from 'react-responsive'

import LanguageContext from './components/LanguageContext.js';

import MainMenu from './components/MainMenu.js';
import DesktopMenu from './components/DesktopMenu.js';
import QuestMapCharacter from './components/QuestMapCharacter.js';
import DSGVO from './components/DSGVO.js';
import Impressum from './components/Impressum.js';
import Footer from './components/Footer.js';
import FAQ from './components/FAQ.js';
import CodeWord from './components/CodeWord.js';

import testRoute from './content/testRoute';
import mdOne, {mdTwo, mdOneEN, mdLndw} from './content/Magdeburg';
import MapMenu from './components/MapMenu.js';
import QuestMapFrame from './components/QuestMapFrame'
import { responsesAreSame } from 'workbox-broadcast-update';


const initscores={
  deA:{name: "Ipsum", time: 99999},
  deB:{name:"Est", time: 99999},
  enA:{name: "", time: 99999},
  enB:{name:"", time: 99999},
}
function App() {

  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  const [highscores, setHighscores]=useState(initscores)
  const [deutsch, setDeutsch]=useState(()=>{
    let initState=Cookies.get('language')
    console.log(initState);
    if(initState===undefined){
      let language = window.navigator.userLanguage || window.navigator.language;
      language=language.substring(0,2)
      
      console.log(language);
      if(language==="en"){
        
        Cookies.set('language', false, { expires: 30 })
        return(false); 
      }
      if(language==="de"){
        
        Cookies.set('language', true, { expires: 30 })
        return(true); 
      } 
    }else{
      return(initState==='true')
    }
    })

    const value={deutsch, setDeutsch}

/* 
    async function fetchStats () {
      try {
        const response = await fetch("../.netlify/functions/get_stats")
        const results=await response.json()
          return results
      } catch (error) {
        console.log(error);
      }
        
      
    }

    useEffect(()=>{
      console.log(fetchStats().then(movies =>{
        console.log(movies);
      }))
    },[]) */
  


  return (
    <>
      <LanguageContext.Provider value={value}>
          <BrowserRouter>
            <Routes>
              {/* {isMobile?<> */}
              <Route path="/" element={<MainMenu  highscores={highscores}/>}/>
              <Route path="/x" element={<QuestMapCharacter contentarray={mdOne} />}/>

              <Route path="/y"element={<QuestMapCharacter contentarray={mdTwo} />}/>
              <Route path="/toax" element={<CodeWord/>}/>
              <Route path="/faq" element={ <FAQ/>}/>
              <Route path="/map" element={ <MapMenu />}/>
              <Route path="/enodm" element={ <QuestMapFrame contentarray={mdOne}/>}/>
              <Route path="/neenodm" element={ <QuestMapFrame contentarray={mdOneEN}/>}/>
              <Route path="/owtdm" element={  <QuestMapFrame contentarray={mdTwo}/>}/>
              <Route path="/wdnldm" element={ <QuestMapFrame contentarray={mdLndw}/>}/>
              <Route path="/testing" element={  <QuestMapFrame contentarray={testRoute}/>}/>
              {/* </>:
              <Route path="/" >
              <DesktopMenu  />
            </Route>
              } */}
              <Route path="/datenschutz" element={<DSGVO />}/>
              <Route path="/impressum" element={<Impressum />}/>
            </Routes>
            <Footer/>
        </BrowserRouter>
      </LanguageContext.Provider>
    </>
  );
}

export default App;
